import React, {useCallback} from "react";
import {InformationButton} from "./Button";
import {Card, CardTitle} from "./Card";

import "../App.css";

export default function BTPBanqueCard(props) {
	const {logUserEvent, userCompanyData} = props;

	const handleClickLink = useCallback(() => {
		logUserEvent({
			event: "btp_banque_survey_click",
			eventData: {
				company_activity_code: userCompanyData.companyActivityCode,
				company_btp_sector_label: userCompanyData.companyBTPSectorLabel,
				company_region_label: userCompanyData.companyRegionLabel,
				company_size_category: userCompanyData.companySizeCategory,
			},
		});

		window.open("https://www.btp-banque.fr", "_blank").focus();
	}, [logUserEvent, userCompanyData]);

	return (
		<Card className="marginTop20">
			<CardTitle>&lt;Titre à fournir par BTP Banque&gt;</CardTitle>

			<div>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam convallis, ante et suscipit facilisis, turpis
					nisi accumsan dolor, vel pretium ante felis id velit. Interdum et malesuada fames ac ante ipsum primis in
					faucibus. Praesent nec lectus id orci finibus porttitor. Duis blandit purus eget pulvinar dictum.
				</p>
			</div>

			<div className="marginTop10">
				<InformationButton className="bg-emphasis" onClick={handleClickLink}>
					Consulter l&apos;enquête
				</InformationButton>
			</div>
		</Card>
	);
}
