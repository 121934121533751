import React from "react";
import {createBrowserRouter, RouterProvider, Outlet, Navigate} from "react-router-dom";

import NavMenu from "./components/NavMenu";
import Welcome from "./components/Welcome";
import Financials from "./components/Financials";
import Report from "./components/Report";
import HelpCenter from "./components/HelpCenter";
import NotFound from "./components/NotFound";
import CGU from "./components/CGU";
import SigninOIDC from "./components/SigninOIDC";

const Routes = (props) => {
	const router = createBrowserRouter([
		{
			path: "/signin-oidc",
			element: <SigninOIDC {...props} />,
		},
		{
			path: "/",
			element: (
				<div className="App">
					<NavMenu {...props} />
					<div className="appContent">
						<Outlet />
					</div>
				</div>
			),
			errorElement: <NotFound />,
			children: [
				{
					path: "/",
					element: <Navigate to="/bienvenue" replace />,
				},
				{
					path: "/bienvenue",
					element: <Welcome {...props} />,
				},
				{
					path: "/etats-financiers",
					element: <Financials {...props} />,
				},
				{
					path: "/analyses",
					element: <Report {...props} />,
				},
				{
					path: "/centre-aide",
					element: <HelpCenter {...props} />,
				},
				{
					path: "/conditions-generales-utilisation",
					element: <CGU {...props} />,
				},
			],
		},
		// {
		// path: "/maintenance",
		// element: <Maintenance {...props} />,
		// },
	]);

	return <RouterProvider router={router} />;
};

export default Routes;
