import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {LinkButton} from "./Button";

import "../App.css";
import "./CGU.css";

function CGU() {
	const navigate = useNavigate();

	const handleBackToStart = useCallback(() => {
		navigate("/bienvenue");
	}, [navigate]);

	return (
		<div className="cgu">
			<div className="centerAlign bold fontSize12 uppercase marginBottom20">
				Mentions légales & Conditions générales d&apos;utilisation
			</div>

			<LinkButton className="marginBottom20" onClick={handleBackToStart}>
				<i className="bi bi-chevron-left marginRight" />
				Retour à l&apos;accueil
			</LinkButton>

			<div className="cgu-paragraph">
				La poursuite de la navigation sur ce site (ci-après « le Site ») vaut acceptation sans réserve des dispositions
				et conditions générales d&apos;utilisation qui suivent.
			</div>
			<div className="cgu-paragraph">
				Seule la version actuellement en ligne des conditions générales d&apos;utilisation est opposable pendant toute
				la durée d&apos;utilisation du Site et jusqu&apos;à ce qu&apos;une nouvelle version la remplace.
			</div>
			<div className="cgu-paragraph">Dernière mise à jour : septembre 2024</div>

			<div className="cgu-h1">Informations légales</div>

			<div className="cgu-h2">
				1.1 Éditeurs-Directeurs de la publication (ci-après désignés conjointement « l&apos;Éditeur »)
			</div>

			<div className="cgu-paragraph">
				<span className="uppercase">Fédération Française du Bâtiment</span>
				<br />
				Union de syndicats professionnels régie par les articles L.2131-1 et suivants du Code du travail
				<br />
				33, avenue Kléber – 75784 Paris Cedex 16
				<br />
				No SIRET: 784 668 642 000 19
			</div>
			<div className="cgu-paragraph">&</div>
			<div className="cgu-paragraph">
				<span className="uppercase">La Banque du Bâtiment et des Travaux Publics</span>
				<br />
				Société anonyme à directoire et conseil de surveillance au capital de 91 512 530 euros
				<br />
				48, rue la Pérouse - CS 51686 – 75773 Paris
				<br />
				RCS Paris 339 182 784
			</div>

			<div className="cgu-h2">1.2 Développement, Conception, Design & Hébergement du Site</div>

			<div className="cgu-paragraph">
				KERYS.CO
				<br />
				Société par Actions Simplifiée au capital de 10 000 €<br />
				95, avenue du Président Wilson, 93108 Montreuil
				<br />
				RCS Bobigny 822 728 689
			</div>

			<div className="cgu-h1">Article 2 - Accès au Site</div>

			<div className="cgu-paragraph">
				L&apos;accès au Site et son utilisation sont réservés aux adhérents FFB et uniquement pour un usage strictement
				personnel. Vous vous engagez à ne pas utiliser ce Site et les informations ou données qui y figurent à des fins
				commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment
				l&apos;envoi de courriers électroniques non sollicités.
			</div>

			<div className="cgu-h1">Article 3 - Contenu du Site</div>

			<div className="cgu-paragraph">
				Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo,
				sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce
				Site et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en
				vigueur au titre de la propriété intellectuelle.
			</div>
			<div className="cgu-paragraph">
				Ils sont la propriété pleine et entière de l&apos;Éditeur ou de ses partenaires. Toute reproduction,
				représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y
				compris les applications informatiques, sans l&apos;accord préalable et écrit de l&apos;Éditeur, sont
				strictement interdites.
			</div>

			<div className="cgu-paragraph">
				Le fait pour l&apos;Éditeur et/ou ses partenaires de ne pas engager de procédure dès la prise de connaissance de
				ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.
			</div>

			<div className="cgu-h1">Article 4 - Gestion du Site</div>

			<div className="cgu-paragraph">
				Pour la bonne gestion du Site, l&apos;Éditeur pourra à tout moment :
				<ul>
					<li>
						suspendre, interrompre ou limiter l&apos;accès à tout ou partie du Site, réserver l&apos;accès au Site, ou à
						certaines parties du Site, à une catégorie déterminée d&apos;internautes ;
					</li>
					<li>
						supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois
						nationales ou internationales, ou avec les règles de la Nétiquette ;
					</li>
					<li>suspendre le Site afin de procéder à des mises à jour.</li>
				</ul>
			</div>

			<div className="cgu-h1">Article 5 - Responsabilités</div>

			<div className="cgu-paragraph">
				La responsabilité de l&apos;Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou
				interruption de fonctionnement, empêchant l&apos;accès au Site ou à une de ses fonctionnalités.
			</div>
			<div className="cgu-paragraph">
				Le matériel de connexion au Site que vous utilisez est sous votre entière responsabilité. Vous devez prendre
				toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d&apos;attaques
				virales par Internet. Vous êtes par ailleurs le seul responsable des sites et données que vous consultez.
			</div>
			<div className="cgu-paragraph">
				L&apos;Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :
				<ul>
					<li>du fait de l&apos;usage du Site ou de tout site externe/service accessible via Internet ;</li>
					<li>du fait du non-respect par vous des présentes conditions générales.</li>
				</ul>
			</div>

			<div className="cgu-paragraph">
				En outre, l&apos;Éditeur n&apos;est pas responsable des dommages directs ou indirects causés à vous-même, à des
				tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du Site et vous renoncez à
				toute action contre lui de ce fait.
			</div>

			<div className="cgu-paragraph">
				Le contenu du Site est présenté sans aucune garantie de quelque nature que ce soit. Il est à ce titre rappelé
				que les informations publiées sur ce Site ne constituent pas des avis ou conseils à caractère professionnel ou
				juridique, pour lesquels il est nécessaire de consulter un professionnel qualifié.
			</div>

			<div className="cgu-paragraph">
				Si l&apos;Éditeur venait à faire l&apos;objet d&apos;une procédure amiable ou judiciaire à raison de votre
				utilisation du Site, il pourra se retourner contre vous pour obtenir indemnisation de tous les préjudices,
				sommes, condamnations et frais qui pourraient découler de cette procédure.
			</div>

			<div className="cgu-h1">Article 6 - Liens hypertextes</div>

			<div className="cgu-paragraph">
				La mise en place par vos soins de tous liens hypertextes vers tout ou partie du Site est strictement interdite,
				sauf autorisation préalable et écrite de l&apos;Éditeur, sollicitée par courrier à l&apos;adresse figurant à
				l&apos;article 1.1.
			</div>
			<div className="cgu-paragraph">
				L&apos;Éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa
				décision. Dans le cas où l&apos;Éditeur accorderait son autorisation, celle-ci n&apos;est dans tous les cas que
				temporaire et pourra être retirée à tout moment, sans obligation de justification à la charge de l&apos;Éditeur.
			</div>
			<div className="cgu-paragraph">
				Dans tous les cas, tout lien devra être retiré sur simple demande de l&apos;Éditeur.
			</div>
			<div className="cgu-paragraph">
				Toute information accessible via un lien vers d&apos;autres sites n&apos;est pas sous le contrôle de
				l&apos;Éditeur qui décline toute responsabilité quant à leur contenu.
			</div>

			<div className="cgu-h1">Article 7 - Collecte de données personnelles</div>

			<div className="cgu-paragraph">
				L&apos;Éditeur est soucieux de la protection des données personnelles et de la vie privée des utilisateurs
				accédant au Site.
			</div>
			<div className="cgu-paragraph">
				À ce titre, vous êtes informé(e) que, dans le cadre de votre utilisation du Site, certaines de vos données
				personnelles peuvent être collectées et traitées, conformément aux dispositions légales et règlementaires en
				vigueur.
			</div>
			<div className="cgu-paragraph">
				Pour rappel, on entend par « données personnelles » toute information relative à une personne physique
				identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro
				d&apos;identification ou à un ou plusieurs éléments qui lui sont propres. L&apos;Éditeur s&apos;engage à ne
				collecter vos données personnelles que pour des finalités précises, explicites et légitimes et à ne pas traiter
				ces mêmes données de manière incompatible avec les finalités en question.
			</div>
			<div className="cgu-paragraph">Les données personnelles pouvant être collectées sont les suivantes :</div>
			<div className="cgu-paragraph">
				Connexion au site : à cette occasion, sont notamment enregistrées, vos données de connexion, de navigation ou
				encore de localisation.
			</div>
			<div className="cgu-paragraph">
				Utilisation de l&apos;outil « BATIPERF » : à cette occasion, sont notamment enregistrées, les éléments
				financiers que vous rentrez dans l&apos;outil.
			</div>
			<div className="cgu-paragraph">
				Contact : Lors du remplissage du formulaire de contact, sont collectés vos nom, prénom, adresse électronique et
				votre message.
				<br />
				Cookies : les Cookies sont utilisés, dans le cadre de l&apos;utilisation du Site, afin de collecter certaines
				informations (en particulier, votre adresse IP, des informations relatives à l&apos;ordinateur utilisé pour la
				navigation, le mode de connexion, le type et la version du navigateur internet, le système d&apos;exploitation
				et d&apos;autres identifiants techniques ou encore l&apos;adresse URL des connexions, y compris la date et
				l&apos;heure, ainsi que le contenu accédé). Vous avez la possibilité de désactiver les Cookies à partir des
				paramètres de votre navigateur. Plus de détails sur l&apos;utilisation des cookies sont disponibles en infra.
			</div>
			<div className="cgu-paragraph">
				Les utilisations de vos données personnelles sont principalement les suivantes :
				<ul>
					<li>Accès et utilisation du Site ;</li>
					<li>Optimisation de l&apos;agencement et du fonctionnement du Site ;</li>
					<li>Gestion de la relation avec vous ;</li>
					<li>Mise en ligne d&apos;informations adéquates, actuelles et pertinentes ;</li>
					<li>Lutte contre les fraudes, utilisations abusives, virus et autres logiciels malveillants ;</li>
					<li>Accomplissement des obligations légales et règlementaires imposées à l&apos;Éditeur ;</li>
					<li>
						Mise en place de statistiques internes et de mesures d&apos;audience, destinées à améliorer la qualité des
						services et informations fournis sur le Site ;
					</li>
					<li>Fourniture d&apos;une assistance utilisateurs.</li>
				</ul>
			</div>
			<div className="cgu-paragraph">
				Lorsque certaines informations sont obligatoires pour accéder à des rubriques, fonctionnalités ou services
				spécifiques du Site ou pour déposer votre candidature, ce caractère obligatoire est mentionné sur le Site. En
				cas de refus de votre part de fournir des informations obligatoires, vous pourriez ne pas avoir accès à certains
				services, fonctionnalités ou rubriques du Site ou ne pas pouvoir déposer votre candidature.
			</div>
			<div className="cgu-paragraph">
				Sous réserve de ce qui suit, les informations personnelles collectées seront conservées pendant la durée
				nécessaire à la réalisation des finalités évoquées précédemment, sauf si vous exercez vos droits légaux dans les
				conditions décrites ci-après ou si une période de conservation spécifique est autorisée ou imposée par une
				disposition légale ou règlementaire. A noter cependant que toutes les données saisies ou chargées via fichier
				sont stockées conservées uniquement le temps de votre session sur l&apos;outil, pour une durée de 4 heures, et
				effacées ensuite.
			</div>
			<div className="cgu-paragraph">
				Pendant ladite période de conservation, l&apos;Éditeur met en place les moyens organisationnels, logiciels,
				juridiques, techniques et physiques aptes à assurer la confidentialité et la sécurité de vos données
				personnelles, de manière à empêcher leur endommagement, effacement ou accès par des tiers non autorisés.
			</div>
			<div className="cgu-paragraph">
				L&apos;accès à vos données personnelles est strictement limité aux employés et préposés de l&apos;Éditeur (à
				l&apos;exception des données financières que vous saisissez directement sur le Site, auxquelles vous êtes seul à
				avoir accès). Cependant, vous êtes informé(e) que l&apos;Éditeur peut éventuellement transmettre certaines de
				vos données personnelles (sous réserve qu&apos;il y ait accès, ce qui n&apos;est pas le cas des données
				financières que vous rentrez dans l&apos;outil BATIPERF) à ses partenaires ou à ses prestataires, chargés
				contractuellement de l&apos;exécution des tâches nécessaires au bon fonctionnement du Site ou à la fourniture
				des services proposés sur le Site. Ces différents destinataires n&apos;ont qu&apos;un accès limité à vos données
				et ont l&apos;obligation de les utiliser en conformité avec les dispositions de la législation applicable en
				matière de protection des données personnelles. Enfin, en cas de transfert de vos données personnelles en dehors
				de l&apos;Union européenne (« U.E. »), il est rappelé que lesdits destinataires seraient tenus par contrat de
				mettre en œuvre les efforts et moyens nécessaires afin de garantir un niveau de protection équivalent à celui
				fourni au sein de l&apos;U.E.
			</div>
			<div className="cgu-paragraph">
				En dehors des cas énoncés ci-dessus, l&apos;Éditeur s&apos;engage à ne pas vendre, louer, céder ou donner accès
				à des tiers à vos données sans votre consentement préalable, à moins d&apos;y être contraint en raison d&apos;un
				motif légitime (obligation légale, lutte contre la fraude ou l&apos;abus, exercice des droits de la défense,
				etc.).
			</div>
			<div className="cgu-paragraph">
				Conformément aux dispositions légales et règlementaires applicables, vous disposez des droits suivants :
				<ul>
					<li>Accès aux données qui vous concernent ;</li>
					<li>Mise à jour de vos données, si celles-ci sont inexactes ;</li>
					<li>Portabilité ou suppression de vos données ;</li>
					<li>Limitation du traitement de vos données ;</li>
					<li>Opposition, pour des motifs légitimes, au traitement de vos données.</li>
				</ul>
			</div>
			<div className="cgu-paragraph">
				Ces différents droits sont à exercer en adressant un courrier à la FFB, via les coordonnées figurant à
				l&apos;article 1.1. Pour des raisons de sécurité et éviter toute demande frauduleuse, cette demande devra être
				accompagnée d&apos;un justificatif d&apos;identité. Le justificatif sera détruit une fois la demande traitée.
			</div>
			<div className="cgu-paragraph">
				En cas de difficulté en lien avec la gestion de vos données personnelles, vous pouvez adresser une réclamation
				auprès de la Commission Nationale de l&apos;Informatique et des Libertés (CNIL) ou de toute autre autorité
				compétente.
			</div>

			<div className="cgu-h1">Article 8 - Cookies</div>

			<div className="cgu-paragraph">
				Lors de la consultation du Site, des Cookies sont déposés sur votre ordinateur, votre mobile ou votre tablette.
			</div>
			<div className="cgu-paragraph">
				Un Cookie est une information déposée sur votre appareil de navigation par le serveur du Site visité. Certaines
				parties du Site peuvent ainsi ne pas être fonctionnelles sans l&apos;acceptation de cookies par
				l&apos;utilisateur.
			</div>
			<div className="cgu-paragraph">
				Les informations collectées par ce biais ne seront utilisées que pour suivre le volume, le type et la
				configuration du trafic utilisant ce Site, pour en développer la conception et l&apos;agencement et à
				d&apos;autres fins administratives et de planification et plus généralement pour améliorer le service que nous
				vous offrons.
			</div>
			<div className="cgu-paragraph">
				Ces informations ne seront pas, sauf accord de votre part, conservées plus de 13 mois.
			</div>
			<div className="cgu-paragraph">
				Vous avez la possibilité de désactiver les Cookies depuis les paramètres de votre navigateur.
			</div>

			<div className="cgu-h1">Article 9 - Loi applicable</div>

			<div className="cgu-paragraph">
				Les présentes conditions d&apos;utilisation du Site sont régies par la loi française et soumises à la compétence
				des tribunaux de Paris, sous réserve d&apos;une attribution de compétence spécifique découlant d&apos;un texte
				de loi ou réglementaire particulier.
			</div>
		</div>
	);
}

export default CGU;
