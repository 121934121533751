import React from "react";
import classNames from "classnames";
import {ReactComponent as RadioBtnSvg} from "../images/icons/radio-btn-opt2.svg";

import "./CustomRadioBtn.css";

export default function CustomRadioBtn(props) {
	return (
		<label className={classNames("customRadioBtn", props.className ? props.className : "")}>
			<input
				type="radio"
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				checked={props.checked}
				disabled={props.disabled}
			/>
			<div className="radioBtn">
				<RadioBtnSvg />
			</div>
			{props.labelText && <div className="labelText">{props.labelText}</div>}
		</label>
	);
}
