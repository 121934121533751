import React, {Component} from "react";
import axios from "axios";
import config from "../config.js";
import {StackableModal, ModalBody, ModalFooter, ModalCloseButton} from "./Modal";
import {Select} from "./Select";
import {MainButton} from "./Button";
// import LFInfoTip from './LFInfoTip';
import Loader from "./Loader";
import {monthsLowerLong} from "./misc-parameters";
import dayjs from "./dayjs-extended";
import classNames from "classnames";

import "../App.css";
import "./AccountsLoader.css";

const monthOptions = monthsLowerLong.map((month, idx) => ({
	value: idx,
	label: month,
}));

export const BilanCompletTemplateLink = (props) => (
	<a
		href="../../Modele liasse fiscale - BILAN COMPLET_v1.1.xlsx"
		className={classNames("color-emphasis", props.className)}
		download
		target="_blank"
		rel="noopener noreferrer"
	>
		{/*<i className="bi bi-download" />*/}
		Téléchargez le modèle de formulaire de bilan complet (2050)
	</a>
);

export const BilanSimplifieTemplateLink = (props) => (
	<a
		href="../../Modele liasse fiscale - BILAN SIMPLIFIE_v1.1.xlsx"
		className={classNames("color-emphasis", props.className)}
		download
		target="_blank"
		rel="noopener noreferrer"
	>
		{/*<i className="bi bi-download" />*/}
		Téléchargez le modèle de formulaire de bilan simplifié (2033)
	</a>
);

function AccountsUploadFeedback(props) {
	var accountsUploadStatusCode = null;

	const {show} = props;

	if (show) {
		if (props.uploadResult) {
			accountsUploadStatusCode =
				typeof props.uploadResult.statusCode !== "undefined"
					? props.uploadResult.statusCode
					: props.accountsUploadStatusCode;
		} else {
			accountsUploadStatusCode = props.accountsUploadStatusCode;
		}

		var accountsUploadFeedback = null;

		if (accountsUploadStatusCode !== null) {
			if (accountsUploadStatusCode === 200) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Les éléments financiers envoyés ont bien été chargés.</p>
						<p>Vous pouvez dès à présent les retrouver dans la liste des états financiers disponibles.</p>
					</React.Fragment>
				);
			} else if (accountsUploadStatusCode === 202) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Le fichier a bien été transmis.</p>
						<p>
							<b>Toutefois aucun montant n&apos;a pu en être extrait.</b>
						</p>
						<p>Nous vous invitons à vérifier le fichier téléchargé.</p>
					</React.Fragment>
				);
			} else if (accountsUploadStatusCode === 403) {
				accountsUploadFeedback =
					props.uploadResult.accountFileType === "XLSX" ? (
						<React.Fragment>
							<p>Le fichier a bien été transmis.</p>
							<p>
								<b>Toutefois il semble que le code Siren ne corresponde pas à celui de l&apos;entreprise.</b>
							</p>
							<p>Nous vous invitons à vérifier le fichier Excel téléchargé.</p>
							<p>
								En particulier, veillez à bien saisir le code Siren de l&apos;entreprise dans l&apos;entête du
								formulaire.
							</p>
						</React.Fragment>
					) : (
						<React.Fragment>
							<p>Le fichier a bien été transmis.</p>
							<p>
								<b>
									Toutefois les données d&apos;identification de l&apos;entreprise n&apos;ont pu être déchiffrées
									automatiquement.
								</b>
							</p>
							<p>Nous vous invitons à vérifier le fichier téléchargé.</p>
						</React.Fragment>
					);
			} else if (accountsUploadStatusCode === 405) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Le fichier a bien été transmis.</p>
						<p>
							<b>
								Toutefois il semble que le document téléchargé ne correspond pas à l&apos;un des formats acceptés
								(liasse fiscale en PDF ou XLSX).
							</b>
						</p>
						<p>Nous vous invitons à vérifier le fichier téléchargé.</p>
					</React.Fragment>
				);
			} else if (accountsUploadStatusCode === 406) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Le fichier a bien été transmis.</p>
						<p>
							<b>
								Toutefois il semble que la date de clôture d&apos;exercice indiquée ne corresponde pas à celle de
								l&apos;entreprise, ou, s&apos;il s&apos;agit d&apos;une liasse au format Excel, que la date de clôture
								et la durée d&apos;exercice renseignées ne soient pas cohérentes.
							</b>
						</p>
						<p>Nous vous invitons à vérifier le fichier téléchargé.</p>
					</React.Fragment>
				);
			} else if (accountsUploadStatusCode === 467) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Le fichier a bien été transmis.</p>
						<p>
							<b>
								Toutefois il semble que la date de clôture d&apos;exercice indiquée ne corresponde pas à celle de
								l&apos;entreprise, ou, s&apos;il s&apos;agit d&apos;une liasse au format Excel, que la date de clôture
								et la durée d&apos;exercice renseignées ne soient pas cohérentes.
							</b>
						</p>
						<p>Nous vous invitons à vérifier le fichier téléchargé.</p>
					</React.Fragment>
				);
			} else if (accountsUploadStatusCode === 413) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>
							<b>Le fichier que vous essayez d&apos;envoyer est trop volumieux et n&apos;a pu être transmis.</b>
						</p>
						<p>
							Nous vous invitons à réessayer l&apos;envoi après avoir réduit la taille du fichier si c&apos;est possible
							(par exemple en supprimant les pages inutiles).
						</p>
					</React.Fragment>
				);
			} else if (accountsUploadStatusCode === 999) {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Une erreur s&apos;est produite.</p>
						<p>Nous vous invitons à réessayer.</p>
					</React.Fragment>
				);
			} else {
				accountsUploadFeedback = (
					<React.Fragment>
						<p>Le fichier a bien été transmis.</p>
						<p>
							<b>Toutefois, les données n&apos;ont pu être déchiffrées automatiquement.</b>
						</p>
						<p>Pour rappel, les formats acceptés sont les suivants :</p>

						{
							// Liasse Excel
							<div className="flex alignItemsCenter marginTop10">
								<i className="bi bi-filetype-xlsx fileTypeIcon" />
								<div className="fontSize09 marginLeft10">
									<div>Liasse fiscale Excel</div>
									<ul>
										<li>
											{/*Modèle de formulaire de bilan complet (2050)*/}
											<BilanCompletTemplateLink />
										</li>
										<li>
											{/*Modèle de formulaire de bilan simplifié (2033)*/}
											<BilanSimplifieTemplateLink />
										</li>
									</ul>
								</div>
							</div>
						}

						{
							// Liasse PDF
							<div className="flex alignItemsCenter marginTop10">
								<i className="bi bi-filetype-pdf fileTypeIcon" />
								<div className="fontSize09 marginLeft10">
									<div>Liasse fiscale PDF</div>
									<ul>
										<li>
											Bilan complet : formulaire DGFIP 2050, 2051, 2052 et 2053
											{/*<LFInfoTip imageFileName="2050-liasse_2221-01.png"/>*/}
										</li>
										<li>
											Bilan simplifié : formulaire DGFIP 2033 A et B
											{/*<LFInfoTip imageFileName="2033-sd_1719-1.png"/>*/}
										</li>
										<li>Plaquette PDF issue d&apos;outil comptable comprenant les formulaires cités ci-dessus</li>
									</ul>
								</div>
							</div>
						}

						{
							// Sauvegarde Excel
							<div className="flex alignItemsCenter marginTop10">
								<i className="bi bi-filetype-xlsx fileTypeIcon" />
								<div className="fontSize09 marginLeft10">
									<div>Sauvegarde Excel d&apos;une saisie manuelle précédente</div>
								</div>
							</div>
						}

						<div className="marginTop20">
							Si vous ne disposez d&apos;aucun de ces documents, ou bien si le traitement de la liasse fiscale PDF a
							échoué, nous vous invitons à saisir manuellement les éléments financiers nécessaires.
						</div>
					</React.Fragment>
				);
			}
		}
	}

	return (
		<StackableModal
			show={accountsUploadFeedback && show}
			dialogClassName="file-sent-modal-dialog"
			onHide={props.onHide}
			centered
		>
			<ModalBody>{accountsUploadFeedback}</ModalBody>
			<ModalFooter>
				<MainButton onClick={props.onHide}>Fermer</MainButton>
			</ModalFooter>
		</StackableModal>
	);
}

export class AccountsLoader extends Component {
	constructor(props) {
		super(props);

		const curYear = Number(new Date().getFullYear());
		this.defaultClosingYear = curYear - 1;

		this.initState = {
			showAccountsFileLoader: false,
			accountsFileObj: null,
			accountsFileObjCopy: null,
			accountsFileType: null,
			accountsClosingYear: this.defaultClosingYear,
			accountsClosingMonthIdx: 11,
			accountsPeriodLength: 12,
			showFileSentModal: false,
			uploadResult: null,
			accountsUploadStatusCode: null,
			isUploadingAccountsFile: false,
		};

		this.state = {
			...this.initState,
		};

		this.accountsClosingYearOptions = [-5, -4, -3, -2, -1, 0, 1].map((lag) => ({
			value: curYear + lag,
			label: curYear + lag,
		}));

		this.accountsPeriodLengthOptions = [];
		for (let iM = 1; iM < 24; iM++) {
			this.accountsPeriodLengthOptions.push({
				value: iM,
				label: iM,
			});
		}
	}

	handleEnter = () => {
		const newState = {...this.initState};

		if (this.props.financialsData) {
			newState.accountsClosingYear = this.props.financialsData.year;

			if (this.props.financialsData.data) {
				if (this.props.financialsData.data.fiscalClosingDate) {
					newState.accountsClosingYear = dayjs(this.props.financialsData.data.fiscalClosingDate).year();
					newState.accountsClosingMonthIdx = dayjs(this.props.financialsData.data.fiscalClosingDate).month();
				}
				if (
					typeof this.props.financialsData.data.periodLength !== "undefined" &&
					this.props.financialsData.data.periodLength !== null
				) {
					newState.accountsPeriodLength = this.props.financialsData.data.periodLength;
				}
			}
		}

		this.setState(newState);
	};

	handleClose = () => {
		this.props.setFinancialsAction((prevState) => ({
			...prevState,
			showFileUploader: false,
		}));
	};

	handleShowAddAccountsFile = () => {
		this.setState({showAccountsFileLoader: true});
	};

	handleCloseAccountsFileLoader = () => {
		this.setState({
			...this.initState,
		});
	};

	handleSelectAccountsFile = (event) => {
		const file = event.target.files[0];

		if (typeof file !== "undefined") {
			var fileExtension = "";
			const fileName = file.name;
			const extensionDotIndex = fileName.lastIndexOf(".");
			if (extensionDotIndex > 0) {
				fileExtension = fileName.substring(extensionDotIndex + 1, fileName.length).toLowerCase();
			}

			if (fileExtension === "pdf" || fileExtension === "xlsx") {
				this.setState({
					accountsFileObj: event.target.files[0],
					accountsFileObjCopy: event.target.files[0],
					accountsFileType: fileExtension,
					// accountsClosingYear: this.defaultClosingYear,
					// accountsClosingMonthIdx: 11,
					// accountsPeriodLength: 12,
					accountsUploadStatusCode: null,
				});
			} else {
				this.setState({
					// showFileSentModal: true,
					accountsFileObj: null,
					accountsFileObjCopy: null,
					accountsFileType: null,
					accountsUploadStatusCode: 420,
					uploadResult: null,
				});
			}
		}

		event.target.value = null;
	};

	handleAccountsClosingMonth = ({value}) => {
		this.setState({accountsClosingMonthIdx: value});
	};

	handleAccountsClosingYear = ({value}) => {
		this.setState({accountsClosingYear: value});
	};

	handleAccountsPeriodLength = ({value}) => {
		this.setState({accountsPeriodLength: value});
	};

	handleSendAccountsFile = (event) => {
		event.preventDefault();

		if (this.state.accountsFileObjCopy === null) {
			alert("Veuillez sélectionner un fichier");
			return;
		}

		this.setState({isUploadingAccountsFile: true});

		const formData = new FormData();
		formData.append("file", this.state.accountsFileObjCopy);
		formData.append("siren", this.props.userCompanyData.siren);

		formData.append("closingMonthIndex", this.state.accountsClosingMonthIdx);
		formData.append("closingYear", this.state.accountsClosingYear);
		formData.append("periodLength", this.state.accountsPeriodLength);

		const reqConfig = {
			headers: {
				usercode: this.props.userCode,
				"content-type": "multipart/form-data",
			},
		};

		axios
			.post(
				config.api.server + config.api.root + "/company/" + this.props.userCompanyData.siren + "/financials/file",
				formData,
				reqConfig,
			)
			.then((resp) => {
				// console.log(resp.data);

				this.setState({isUploadingAccountsFile: false});

				if (resp && resp.data) {
					this.handleAfterUploadAccounts(resp.data);
				} else {
					this.handleUploadAccountsError();
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({isUploadingAccountsFile: false});
				this.handleUploadAccountsError(err);
			});

		this.props.logUserEvent({
			event: "financials_load",
			eventData: {
				load_mode: "file - " + this.state.accountsFileType,
			},
		});
	};

	handleAfterUploadAccounts = (uploadedAccountsData) => {
		if (uploadedAccountsData) {
			this.setState({
				...this.initState,
				showFileSentModal: true,
				accountsUploadStatusCode: uploadedAccountsData.statusCode,
				uploadResult: uploadedAccountsData,
				showAccountsFileLoader: false,
			});

			if (uploadedAccountsData.statusCode === 200) {
				this.props.setFinancialsAction({
					showAddFinancials: false,
					showFileUploader: false,
					showFinancialsEditor: false,
					showDeleteFinancialsWarning: false,
					financialsData: null,
				});

				this.props.getFinancials();
			}
		} else {
			this.setState({
				...this.initState,
			});
		}
	};

	handleUploadAccountsError = (error) => {
		if (error && error.response) {
			if (error.response.status === 413) {
				this.setState({
					...this.initState,
					showFileSentModal: true,
					accountsUploadStatusCode: 413,
					uploadResult: typeof error.response.data !== "undefined" ? error.response.data : null,
				});
			} else {
				this.setState({
					...this.initState,
					showFileSentModal: true,
					accountsUploadStatusCode: typeof error.response.status !== "undefined" ? error.response.status : 999,
					uploadResult: typeof error.response.data !== "undefined" ? error.response.data : null,
				});
			}
		} else {
			this.setState({
				...this.initState,
				showFileSentModal: true,
				accountsUploadStatusCode: 999,
				uploadResult: null,
			});
		}
	};

	closeFileSentModal = () => {
		this.setState({
			...this.initState,
		});
	};

	render() {
		// console.log(this.state);

		return (
			<React.Fragment>
				<StackableModal
					show={this.props.show}
					onHide={this.handleClose}
					dialogClassName="financials-uploader-dialog"
					onEnter={this.handleEnter}
					keyboard
					centered
				>
					<ModalBody>
						<ModalCloseButton onClick={this.handleClose} />

						<div className="accountsFileUploader">
							{
								// col 1
								<div className="col1">
									<div className="fontSize12 bold marginBottom20">Chargez une situation financière</div>

									<div className="color-light-note fontSize09">
										Les éléments déposés resteront
										<br />
										strictement <span className="semiBold">confidentiels</span> et à{" "}
										<span className="semiBold">usage privé</span>.
									</div>

									<div className="marginTop20">
										<div className="uploadFileBox">
											<form onSubmit={this.handleSendAccountsFile}>
												<label
													className={
														"b-button uploadBtn" +
														(this.state.accountsFileObj === null ? " main-button" : " secondary-button")
													}
													htmlFor="uploadAccountsInput"
												>
													<input
														type="file"
														accept=".pdf, .xlsx"
														id="uploadAccountsInput"
														name="accountsFile"
														style={{display: "none"}}
														onChange={this.handleSelectAccountsFile}
														disabled={this.state.isUploadingAccountsFile}
													/>
													<span className="step">1</span>
													<br />
													<i className="bi bi-folder2-open marginRight" />
													<span className="noWrap">Sélectionnez le fichier</span>
												</label>

												<div className="centerAlign fileName">
													{this.state.isUploadingAccountsFile ? (
														<Loader />
													) : this.state.accountsUploadStatusCode === 420 ? (
														<span className="color-error">
															Seuls les fichiers au format PDF ou Excel sont autorisés
														</span>
													) : (
														this.state.accountsFileObj && this.state.accountsFileObj.name
													)}
												</div>

												{this.state.accountsFileObj &&
													(this.state.accountsFileType === "xlsx" || this.state.accountsFileType === "unknown") && (
														<div className="marginTop10 marginBottom10">
															{(this.state.accountsFileType === "xlsx" ||
																this.state.accountsFileType === "unknown") && (
																<div className="fileObjInfo">
																	<div>
																		Exercice clos en
																		<Select
																			className="accountsFileInfoSelector accountsClosingMonth marginLeft05"
																			selectedIndex={this.state.accountsClosingMonthIdx}
																			options={monthOptions}
																			onSelectOption={this.handleAccountsClosingMonth}
																			placeholder="Mois"
																			disabled={this.state.isUploadingAccountsFile}
																		/>
																		<Select
																			className="accountsFileInfoSelector marginLeft05"
																			selectedValue={this.state.accountsClosingYear}
																			options={this.accountsClosingYearOptions}
																			onSelectOption={this.handleAccountsClosingYear}
																			placeholder="Année"
																			disabled={this.state.isUploadingAccountsFile}
																		/>
																	</div>
																	{this.state.accountsFileType === "xlsx" && (
																		<div className="marginTop05">
																			Durée de l&apos;exercice :
																			<Select
																				className="accountsFileInfoSelector marginLeft05"
																				selectedValue={this.state.accountsPeriodLength}
																				options={this.accountsPeriodLengthOptions}
																				onSelectOption={this.handleAccountsPeriodLength}
																				disabled={this.state.isUploadingAccountsFile}
																			/>
																			&nbsp;&nbsp;mois
																		</div>
																	)}
																</div>
															)}
														</div>
													)}

												<button
													type="submit"
													className="b-button main-button uploadBtn"
													disabled={
														this.state.accountsFileObj === null ||
														this.state.accountsClosingYear === null ||
														this.state.accountsClosingMonthIdx === null ||
														this.state.isUploadingAccountsFile
													}
												>
													<span className="step">2</span>
													<br />
													{this.state.isUploadingAccountsFile ? (
														<Loader />
													) : (
														<>
															<i className="bi bi-upload marginRight" />
															Envoyez
														</>
													)}
												</button>
											</form>
										</div>
									</div>
								</div>
							}

							{
								// col 2
								<div className="col2">
									<div className="bold">Types de fichiers acceptés</div>

									{
										// Liasse Excel
										<div className="marginTop20">
											<div>
												<span className="bold">Liasse fiscale Excel</span>
											</div>
											<div className="flex alignItemsCenter marginTop10">
												<i className="bi bi-filetype-xlsx fileTypeIcon" />
												<div className="fontSize09 marginLeft10">
													<div>Formulaire à télécharger et à saisir</div>
													<ul>
														<li>
															{/*Modèle de formulaire de bilan complet (2050)*/}
															<BilanCompletTemplateLink />
														</li>
														<li>
															{/*Modèle de formulaire de bilan simplifié (2033)*/}
															<BilanSimplifieTemplateLink />
														</li>
													</ul>
												</div>
											</div>
										</div>
									}

									{
										// Liasse PDF
										<div className="marginTop20">
											<div>
												<span className="bold">Liasse fiscale PDF</span>
											</div>
											<div className="flex alignItemsCenter marginTop10">
												<i className="bi bi-filetype-pdf fileTypeIcon" />
												<div className="fontSize09 marginLeft10">
													<div>Reconnaissance automatique sans saisie</div>
													<ul>
														<li>
															Bilan complet : formulaire DGFIP 2050, 2051, 2052 et 2053
															{/*<LFInfoTip imageFileName="2050-liasse_2221-01.png"/>*/}
														</li>
														<li>
															Bilan simplifié : formulaire DGFIP 2033 A et B
															{/*<LFInfoTip imageFileName="2033-sd_1719-1.png"/>*/}
														</li>
														<li>
															Plaquette PDF issue d&apos;outil comptable comprenant les formulaires cités ci-dessus
														</li>
													</ul>
												</div>
											</div>
										</div>
									}

									{
										// Sauvegarde Excel
										<div className="marginTop20">
											<div>
												<span className="bold">Sauvegarde Excel</span>
											</div>
											<div className="flex alignItemsCenter marginTop10">
												<i className="bi bi-filetype-xlsx fileTypeIcon" />
												<div className="fontSize09 marginLeft10">
													<div>Réutilisez une saisie précédemment sauvegardée sous Excel</div>
												</div>
											</div>
										</div>
									}
								</div>
							}
						</div>
					</ModalBody>
				</StackableModal>

				<AccountsUploadFeedback
					{...this.props}
					show={this.state.showFileSentModal}
					onHide={this.closeFileSentModal}
					uploadResult={this.state.uploadResult}
					accountsUploadStatusCode={this.state.accountsUploadStatusCode}
				/>
			</React.Fragment>
		);
	}
}
