import React, {useCallback} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {LinkButton} from "./Button";
import ElementWithTooltip from "./ElementWithTooltip";
// import config from "../config";
import {ReactComponent as ForekastsLogoSvg} from "../images/misc/Logo_Forekasts.svg";

import "../App.css";
import "./NavMenu.css";

function NavMenu(props) {
	// console.log(props);

	const nav = useNavigate();
	const navigate = useCallback(
		(event) => {
			nav(event.currentTarget.value);
		},
		[nav],
	);

	const currentLocation = useLocation();
	const currentLocationUrl = currentLocation ? currentLocation.pathname : "";

	/*
	const handleSignin = useCallback(() => {
		
		const url = (
			config.auth.url 
			+ "?"
			+ new URLSearchParams({
				client_id: config.auth.clientId,
				redirect_uri: window.location.origin + "/signin-oidc",
				// state: userCode,
				scope: "openid kerys.api",
				response_type: "code",
			}).toString()
		);
// console.log(url);
		
		// https://dev-oidc.ffbatiment.fr/connect/authorize?client_id=undefined&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsignin-oidc&scope=openid+kerys.api&response_type=code
		
		window.location.replace(url);
	}, []);
	*/

	const handleLeave = useCallback(() => {
		window.location.replace("https://www.ffbatiment.fr");
	}, []);

	return (
		<div className="appNavMenu">
			<div>
				<div className="appLogo">
					<button onClick={navigate} value="/bienvenue">
						<img src={require("../images/misc/FFB-Logo BatiPerf_2024_BAT [quadri]_BAT.png")} alt="Logo BatiPerf" />
					</button>
				</div>

				<div className="userData">
					<div className="">
						<div className="centerAlign noWrapEllipsis bold">
							{((props.userFirstName || "").trim() + " " + (props.userLastName || "").trim()).trim()}
						</div>
						{/*<ElementWithTooltip tooltip="Quitter BatiPerf"><LinkButton className="color-emphasis" onClick={handleLeave}><i className="bi bi-box-arrow-right"/></LinkButton></ElementWithTooltip>*/}
					</div>

					{/*<LinkButton onClick={handleSignin}>Cnx</LinkButton>*/}

					<div className="userCompany centerAlign marginTop05">
						{props.userCompanyData && props.userCompanyData.siren && (
							<>
								<ElementWithTooltip tooltip={props.userCompanyData.siren + " - " + props.userCompanyData.companyName}>
									<div className="noWrapEllipsis bold">{props.userCompanyData.companyName}</div>
								</ElementWithTooltip>
								{/*(props.userProfile === "multi-contextual" || props.userProfile === "no-context") && (
									<LinkButton onClick={navigate} value="/bienvenue">
										Changer d&apos;entreprise
									</LinkButton>
								)*/}
							</>
						)}
					</div>
				</div>

				<div className="appNavLinks">
					{props.userCompanyData &&
					props.userCompanyData.siren &&
					(props.userProfile === "multi-contextual" || props.userProfile === "no-context") ? (
						<button onClick={navigate} value="/bienvenue" disabled={currentLocationUrl === "/bienvenue"}>
							<i className="bi bi-buildings marginRight" />
							Changer d&apos;entreprise
						</button>
					) : (
						<div className="button-placeholder" />
					)}
					{[
						["/etats-financiers", "bi-file-ruled", "États financiers", false],
						["/analyses", "bi-graph-up", "Analyses", false],
						["/centre-aide", "bi-question-circle", "Centre d'aide", true],
					].map(([url, iconClassName, label, alwaysActive]) => (
						<button
							key={url}
							onClick={navigate}
							value={url}
							className={url === currentLocationUrl ? "active" : ""}
							disabled={!alwaysActive && (!props.userCompanyData || !props.userCompanyData.siren)}
						>
							<i className={"bi " + iconClassName + " marginRight"} />
							{label}
						</button>
					))}
					<button
						onClick={handleLeave}
						// className={url === currentLocationUrl ? "active" : ""}
					>
						<i className="bi bi-box-arrow-right marginRight" />
						Quitter BatiPerf
					</button>
				</div>
			</div>

			<div className="navMenuFooter">
				<div className="flex alignItemsCenter justifySpaceAround">
					<a href="https://www.ffbatiment.fr" target="_blank" rel="noopener noreferrer">
						<img
							src={require("../images/misc/logo-federation-francaise-du-batiment.png")}
							alt="Logo Fédération Française du Bâtiment"
						/>
					</a>
					<a href="https://www.btp-banque.fr" target="_blank" rel="noopener noreferrer">
						<img src={require("../images/misc/logo-btp-banque-horizontal.png")} alt="Logo BTP Banque" />
					</a>
					{/*<a href="https://www.btp-banque.fr" target="_blank" rel="noopener noreferrer"><img src={require("../images/misc/logo-btp-banque-vertical.png")} alt="Logo BTP Banque"/></a>*/}
				</div>
				<div className="fkLogo flex alignItemsBaseline justifyCenter fontSize075 color-light-note semiBold marginTop10">
					<span className="marginRight10">Réalisé par</span>
					<ForekastsLogoSvg />
				</div>
				<div className="fontSize075 marginTop05">
					<LinkButton onClick={navigate} value="/conditions-generales-utilisation">
						Conditions Générales d&apos;Utilisation
					</LinkButton>
				</div>
			</div>
		</div>
	);
}

export default NavMenu;
