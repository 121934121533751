import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {Card, CardTitle} from "./Card";
import {MainButton, LinkButton} from "./Button";
import ContactHelpDesk from "./ContactHelpDesk";

import "../App.css";
import "./HelpCenter.css";

const faq = [
	{
		question: "D'où les données comparatives proviennent-elles ?",
		answer: (
			<>
				<div className="">
					Les données comparatives sont issues de l&apos;étude financière sectorielle annuelle publiée par BTP Banque.
				</div>
			</>
		),
	},
	{
		question: "Sur quelles entreprises puis-je faire une analyse ?",
		answer: (
			<>
				<div className="">
					En tant qu&apos;adhérent à la FFB, il vous est possible de faire une analyse sur l&apos;entreprise, ou
					éventuellement les entreprises, rattachées à votre compte FFB.
				</div>
				<div className="">
					Les utilisateurs ayant un statut de permanent FFB, ou d&apos;invités disposant d&apos;un compte sur le site
					FFB, peuvent faire une analyse sur toute entreprise de leur choix du secteur du bâtiment.
				</div>
				<div className="">
					<b>A noter pour les groupes de sociétés :</b>&nbsp;L&apos;analyse comparative n&apos;a de sens que sur les sociétés 
					de production. Les holdings, par leur structure de bilan spécifique, ne peuvent être comparées aux indicateurs sectoriels.
				</div>
			</>
		),
	},
	{
		question: "Mon entreprise n'est pas reconnue, comment faire ?",
		answer: (
			<>
				<div className="">
					Dans le cas où votre compte FFB vous permet de choisir l&apos;entreprise à analyser, vous pouvez effectuer une
					recherche par nom ou par code SIREN.
					<br />
					L&apos;ensemble des entreprises enregistrées à l&apos;INSEE, d&apos;un secteur d&apos;activité du bâtiment ou
					des travaux publics, est référencé.
					<br />
					La recherche d&apos;une entreprise par son nom peut, dans certains cas, donner de nombreux résultats. Dans ce
					cas privilégiez la recherche par code SIREN.
				</div>
			</>
		),
	},
	{
		question: "Comment puis-je charger les données de mon entreprise ?",
		answer: (
			<>
				<div className="">
					Si l&apos;entreprise a déposé des comptes annuels au greffe, sans mention de confidentialité, ils seront
					récupérés automatiquement.
				</div>
				<div className="">Vous pouvez compléter ces données par différents moyens :</div>
				<ul>
					<li>Chargement de liasse fiscale Excel</li>
					<li>Chargement de liasse fiscale PDF</li>
					<li>Saisie manuelle</li>
					<li>Chargement d&apos;une sauvegarde Excel</li>
				</ul>
			</>
		),
	},
	{
		question: "Quels types de données sont nécessaires ?",
		answer: (
			<>
				<div className="">
					Les données à renseigner correspondent à des éléments du compte de résultat, du bilan, et certaines données
					annexes.
				</div>
				<div className="">
					Toutes les informations nécessaires peuvent être trouvées dans la liasse fiscale de l&apos;entreprise,
					produite au moment de la clôture des comptes.
				</div>
			</>
		),
	},
	{
		question: "Sur quelle période historique puis-je analyser mes indicateurs ?",
		answer: (
			<>
				<div className="">
					Vous pouvez comparer les indicateurs de votre entreprise aux données sectorielles sur les 5 dernières années
					publiées de l&apos;étude BTP Banque.
				</div>
				<div className="">
					<b>A noter :</b>
					<ul>
						<li>
							L&apos;étude BTP Banque est publiée chaque année en septembre, pour les données de l&apos;année
							précédente.
						</li>
						<li>
							Les données comparatives annuelles sont calculées pour les bilans clos du 1er juillet de l&apos;année en
							cours au 30 juin de l&apos;année suivante.
							<br />
							Par exemple, les valeurs sectorielles indiquées pour l&apos;année 2022 correspondent aux bilans de juillet
							2022 à juin 2023.
							<br />
							Les données de votre entreprise seront rapprochées selon cette même règle.
						</li>
					</ul>
				</div>
			</>
		),
	},
	{
		question: "Les données que je saisis sont-elles confidentielles ?",
		answer: (
			<>
				<div className="">Oui totalement.</div>
				<div className="">
					Toutes les données saisies ou chargées via fichier sont considérées comme strictement confidentielles. Vous
					seul y avez accès.
				</div>
				<div className="">
					Vos données sont stockées sur nos serveurs uniquement le temps de votre session sur l&apos;outil, pour une
					durée de 4 heures, et effacées ensuite.
				</div>
			</>
		),
	},
	{
		question: "Comment les ratios comparatifs sont-ils calculés ?",
		answer: (
			<>
				<div className="">
					Les ratios sont calculés sur la base des comptes annuels déposés publiquement par les entreprises françaises.
				</div>
				<div className="">
					Toutes les tailles d&apos;entreprises éligibles à l&apos;IS sont prises en compte, y compris les TPE et
					Majors.
				</div>
			</>
		),
	},
	{
		question: "Avertissement sur les commentaires automatiques",
		answer: (
			<>
				<div className="">
					Les éventuels commentaires automatiques qui apparaissent à côté des indicateurs n&apos;ont qu&apos;une portée
					indicative.
					<br />
					En l&apos;absence d&apos;informations détaillées et, plus encore, de connaissance fine de l&apos;entreprise et
					de son fonctionnement, ils ne peuvent remplacer l&apos;analyse faite par un conseil.
				</div>
			</>
		),
	},
];

function HelpCenter(props) {
	// const [showContactHelpDesk, setShowContactHelpDesk] = useState(false);

	const navigate = useNavigate();

	const handleBackToStart = useCallback(() => {
		navigate("/bienvenue");
	}, [navigate]);

	/*
	const handleShowContactHelpDesk = useCallback(() => {
		setShowContactHelpDesk(true);
	}, []);

	const handleHideContactHelpDesk = useCallback(() => {
		setShowContactHelpDesk(false);
	}, []);
	*/

	return (
		<>
			<Card>
				<CardTitle>Centre d&apos;aide</CardTitle>

				<LinkButton onClick={handleBackToStart}>
					<i className="bi bi-chevron-left marginRight" />
					Retour à l&apos;accueil
				</LinkButton>

				<div className="bold fontSize11 marginTop20">Foire aux questions</div>

				<div className="marginTop20">
					{faq.map(({question, answer}, qIdx) => (
						<div key={qIdx} id={"qna-item-" + (qIdx + 1).toString()} className="qna-item marginTop10">
							<div className="qna-question">
								<i className="bi bi-question-circle color-emphasis marginRight" />
								{question}
							</div>
							<div className="qna-answer flex justifyFlexStart alignItemsFlexStart fontSize09">
								<i className="bi bi-chevron-right color-emphasis marginRight" />
								<div>{answer}</div>
							</div>
						</div>
					))}
				</div>

				<div className="bold fontSize11 marginTop30">Vous n&apos;avez pas trouvé la réponse que vous cherchiez ?</div>

				<ContactHelpDesk
					{...props}
					triggerComponent={(onClick) => (
						<MainButton className="marginTop10" onClick={onClick}>
							Nous contacter
						</MainButton>
					)}
				/>
			</Card>

			{/*<ContactHelpDesk {...props} show={showContactHelpDesk} onHide={handleHideContactHelpDesk} />*/}
		</>
	);
}

export default HelpCenter;
