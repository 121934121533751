import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {formatNumberLocaleString} from "./utils";

const textColor = "#434B52";
const fontSize = "12px";

Highcharts.Templating.helpers.floor = function (value) {
	return Math.floor(value);
};

export const highchartsCommonOptions = {
	exporting: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	title: {
		text: null,
	},
	lang: {
		noData: "Aucune donnée disponible",
		decimalPoint: ",",
		thousandsSep: " ",
	},
	noData: {
		style: {
			fontWeight: "normal",
			fontStyle: "italic",
			fontSize: fontSize,
			color: textColor,
		},
	},
	chart: {
		// type: 'spline',
		// height: (typeof props.height !== "undefined" ? props.height : null),
		spacing: [10, 0, 0, 0],
		style: {
			fontFamily: '"Nunito", "arial", "sans-serif"',
			color: textColor,
		},
	},
	legend: {
		enabled: true,
		verticalAlign: "bottom",
		layout: "horizontal",
		itemStyle: {
			color: textColor,
			// fontWeight: "light",
		},
		margin: 10,
		itemDistance: 10,
	},
	xAxis: {
		// crosshair: {
		// width: 1,
		// color: "#AAAAAA",
		// },
		// plotLines: [],
		labels: {
			style: {
				fontSize: fontSize,
				color: textColor,
			},
		},
		// tickInterval: 3,
		// lineColor: "#E3E7EB",
		lineWidth: 0,
	},
	yAxis: [
		{
			// crosshair: {
			// width: 1,
			// color: "#CECECE",
			// },
			softMin: 0,
			title: {
				// text: "En " + props.currencyText,
				text: null,
				// style: {
				// color: textColor,
				// },
			},
			labels: {
				style: {
					fontSize: fontSize,
					color: textColor,
				},
			},
			// plotLines: [],
			// plotBands: [],
			endOnTick: true,
			alignTicks: false,
		},
	],
	plotOptions: {
		series: {
			marker: {
				enabled: false,
			},
			states: {
				hover: {
					lineWidthPlus: 0,
				},
			},
			// Correction bug série qui reste affichée quand on clique sur la légende (https://github.com/highcharts/highcharts/issues/7088)
			events: {
				hide: function () {
					this.update({});
				},
			},
		},
	},
	tooltip: {
		style: {
			fontSize: fontSize,
			color: textColor,
		},
		backgroundColor: "white",
		borderColor: "#C8CBCD",
		valueDecimals: 2,
		padding: 2,
		shared: true,
		formatter: function () {
			return (
				"<b>" +
				this.x +
				"</b><br/>" +
				this.points
					.map(
						(point) =>
							'<span style="color:' +
							point.color +
							';">●</span> ' +
							point.series.name +
							" : <b>" +
							formatNumberLocaleString(point.y, 0) +
							"</b><br/>",
					)
					.join("")
			);
		},
	},
	series: [],
};

export const Chart = (props) => <HighchartsReact {...props} highcharts={Highcharts} />;
