import React from "react";
import SimpleTooltip from "./SimpleTooltip";

import "./ElementWithTooltip.css";

const ElementWithTooltip = ({children, ...props}) => (
	<SimpleTooltip
		{...props}
		className={"simpleTextTooltip " + (typeof props.className !== "undefined" ? props.className : "")}
		placement={props.placement || "auto"}
	>
		{children}
	</SimpleTooltip>
);

export default ElementWithTooltip;
