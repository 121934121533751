import React from "react";
import classNames from "classnames";

import "./WarningNote.css";

const WarningNote = (props) => (
	<div className={classNames("b-warning-note", props.className)}>
		<i className="bi bi-exclamation-circle-fill color-error marginRight" />
		<div>{props.children}</div>
	</div>
);

export default WarningNote;
