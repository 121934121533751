import React, {Component} from "react";

import "../App.css";
import "./EditableText.css";

export class EditableTextArea extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value:
				typeof this.props.defaultValue !== "undefined" && this.props.defaultValue !== null
					? this.props.defaultValue
					: "",
			textAreaHeight: 34,
		};

		this.textAreaDOMNode = null;

		this.propagChangeTimeout = null;
	}

	componentDidMount() {
		if (this.state.textAreaHeight !== this.textAreaDOMNode.scrollHeight) {
			this.setState({textAreaHeight: this.textAreaDOMNode.scrollHeight + 2});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.textAreaHeight > 0 && this.state.textAreaHeight === 0) {
			this.setState({textAreaHeight: this.textAreaDOMNode.scrollHeight + 2});
		}
	}

	handleChange = (event) => {
		if (this.props.readOnly !== true) {
			const newVal = event.target.value;

			if (typeof this.props.maxCharacters === "undefined" || newVal.length <= this.props.maxCharacters) {
				if (typeof this.props.maxLines === "undefined" || newVal.split("\n").length <= this.props.maxLines) {
					this.setState({value: newVal, textAreaHeight: 0});

					if (this.propagChangeTimeout !== null) {
						clearTimeout(this.propagChangeTimeout);
					}

					this.propagChangeTimeout = setTimeout(
						() => this.propagChange(newVal),
						typeof this.props.changeEventDelay !== "undefined" ? this.props.changeEventDelay : 400,
					);
				}
			}
		}
	};

	propagChange = (newVal) => {
		this.propagChangeTimeout = null;
		if (typeof this.props.onChange !== "undefined") this.props.onChange(newVal);
	};

	render() {
		const textAreaStyle = {height: this.state.textAreaHeight};
		const className = typeof this.props.className !== "undefined" ? this.props.className : "";

		return this.props.staticRender === true ? (
			<div className={className}>
				{this.state.value === null || this.state.value === ""
					? this.props.staticRenderEmptyFallback
					: this.state.value.split("\n").map((item, key) => (
							<React.Fragment key={key}>
								{item}
								<br />
							</React.Fragment>
						))}
			</div>
		) : (
			<div className={className}>
				<textarea
					className={
						"editableTextArea " +
						(typeof this.props.inputClassName !== "undefined" ? this.props.inputClassName : "") +
						(this.state.value === "" ? " placeholderStyle" : "")
					}
					readOnly={this.props.readOnly === true}
					style={textAreaStyle}
					placeholder={this.props.placeholder || ""}
					value={this.state.value}
					onChange={this.handleChange}
					ref={(node) => {
						this.textAreaDOMNode = node;
					}}
				/>
				{typeof this.props.maxCharacters !== "undefined" &&
					(typeof this.props.displayNbCharHelper === "undefined" || this.props.displayNbCharHelper === true) &&
					this.props.readOnly !== true && (
						<div className="maxCharacters">
							<div>
								{this.state.value.length + " / " + this.props.maxCharacters + " caractères"}
								{typeof this.props.maxLines !== "undefined" && (
									<React.Fragment>
										<br />
										{this.state.value.split("\n").length + " / " + this.props.maxLines + " lignes"}
									</React.Fragment>
								)}
							</div>
						</div>
					)}
			</div>
		);
	}
}
