import React, {useState, useEffect} from "react";
import axios from "axios";
import config from "../config";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import {InformationButton} from "./Button";

import "../App.css";

export default function SigninOIDC(props) {
	const {setUserData, handleSignin, logUserEvent} = props;

	const [connectionStatus, setConnectionStatus] = useState("connecting");

	const nav = useNavigate();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		if (searchParams && searchParams.has("code")) {
			const apiBaseUrl = config.api.server;

			axios
				.post(apiBaseUrl + "/auth", {
					authCode: searchParams.get("code"),
				})
				.then((resp) => {
					// console.log(resp);
					if (resp && resp.data.success && resp.data.data) {
						setUserData(resp.data.data);
						logUserEvent({userCode: resp.data.data.id, event: "login"});
						setConnectionStatus("connected");
						nav("/bienvenue");
					} else {
						setUserData(null);
						// handleSignin();
						setConnectionStatus("error");
					}
				})
				.catch((err) => {
					console.log(err);
					// handleSignin();
					setConnectionStatus("error");
				});
			// .then(() => {
			// nav("/bienvenue");
			// });
		} else {
			// nav("/bienvenue");
			// handleSignin();
			setConnectionStatus("error");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{connectionStatus === "connecting" && (
				<>
					<div className="centerAlign marginTop30">
						<Loader />
					</div>
					<div className="centerAlign marginTop20">Authentification en cours</div>
				</>
			)}
			{connectionStatus === "error" && (
				<>
					<div className="centerAlign marginTop30">Nous n&apos;avons pas pu vous authentifier.</div>
					<div className="centerAlign marginTop20">
						<InformationButton className="bg-emphasis" onClick={handleSignin}>
							Connectez-vous pour accéder à BatiPerf
						</InformationButton>
					</div>
				</>
			)}
		</>
	);
}
