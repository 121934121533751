import React from "react";
import InfoNote from "./InfoNote";

import "../App.css";

export function FinancialsOptions() {
	return (
		<>
			<ul className="no-list-style-type">
				<li className="marginTop05">
					<span className="color-emphasis">
						<i className="bi bi-filetype-xlsx fontSize12 marginRight" />
					</span>
					Chargement de liasse fiscale Excel
				</li>
				<li className="marginTop05">
					<span className="color-emphasis">
						<i className="bi bi-filetype-pdf fontSize12 marginRight" />
					</span>
					Chargement de liasse fiscale PDF
				</li>
				<li className="marginTop05">
					<span className="color-emphasis">
						<i className="bi bi-pencil-square fontSize12 marginRight" />
					</span>
					Saisie manuelle
				</li>
				<li className="marginTop05">
					<span className="color-emphasis">
						<i className="bi bi-filetype-xlsx fontSize12 marginRight" />
					</span>
					Chargement d&apos;une sauvegarde Excel
				</li>
			</ul>

			<InfoNote className="marginTop15">
				Les données saisies sont conservées uniquement le temps de la session, pendant une durée de 4h. Vous pouvez à
				tout moment les télécharger au format Excel pour les réutiliser lors d&apos;une autre session.
			</InfoNote>
		</>
	);
}
