export const metrics = [
	"net_margin_ratio",
	"equity_to_sales_ratio",
	"net_cash",
	"client_payment_delay",
	"supplier_payment_delay",
	"equity_to_bs_ratio",
	"personal_costs_to_added_value_ratio",
	"added_value_to_sales_ratio",
	"ebe_to_added_value_ratio",
	"debt_to_afc_ratio",
	"outsourcing_ratio",
	"production_to_workforce_ratio",
];

export const metricParams = {
	net_margin_ratio: {
		label: "Résultat net / CA",
		unit: "percent",
		description: "Mesure la performance globale de l'entreprise (sa profitabilité) par rapport à son activité",
	},
	equity_to_sales_ratio: {
		label: "Fonds propres / CA",
		unit: "percent",
		description: "Exprime la solvabilité de l'entreprise et sa capacité à absorber les pertes",
	},
	net_cash: {
		label: "Trésorerie nette (en jours de production)",
		unit: "none",
		description:
			"Évalue la capacité de l'entreprise à faire face à ses obligations financières à court terme et à maintenir ses opérations courantes sans avoir recours à des financements extérieurs",
	},
	client_payment_delay: {
		label: "Délais clients (en jours de production)",
		unit: "none",
		description: "Détermine la durée moyenne du délai de règlement des clients de l'entreprise",
	},
	supplier_payment_delay: {
		label: "Délais fournisseurs (en jours de production)",
		unit: "none",
		description: "Détermine la durée moyenne du délai de paiement des fournisseurs par l'entreprise",
	},
	equity_to_bs_ratio: {
		label: "Fonds propres / Total bilan",
		unit: "percent",
		description: "Mesure la solidité financière de l'entreprise",
	},
	personal_costs_to_added_value_ratio: {
		label: "Charges de personnel / Valeur ajoutée",
		unit: "percent",
		description: "Évalue la part des frais de personnel dans la valeur ajoutée de l'entreprise",
	},
	added_value_to_sales_ratio: {
		label: "Valeur ajoutée / CA",
		unit: "percent",
		description: "Mesure la capacité de l'entreprise à créer de la valeur dans le cadre de son activité",
	},
	ebe_to_added_value_ratio: {
		label: "EBE / Valeur ajoutée",
		unit: "percent",
		description:
			"Évalue dans quelle mesure le résultat de l'exercice est attribuable à l'activité opérationnelle de l'entreprise (marge opérationnelle)",
	},
	debt_to_afc_ratio: {
		label: "Endettement / CAF",
		unit: "none",
		description: "Exprime la capacité de l'entreprise à rembourser ses dettes au moyen de ses propres ressources",
	},
	outsourcing_ratio: {
		label: "Part de sous-traitance",
		unit: "percent",
		description: "Mesure l'activité sous-traitée par l'entreprise",
	},
	production_to_workforce_ratio: {
		label: "Production propre / Effectif",
		unit: "none",
		description: "Mesure la productivité des salariés de l'entreprise, y compris l'intérim",
	},
};

export const regionCodes = [
	["84", "Auvergne-Rhône-Alpes"],
	["27", "Bourgogne-Franche-Comté"],
	["53", "Bretagne"],
	["24", "Centre-Val de Loire"],
	["94", "Corse"],
	["97", "DOM-TOM"],
	["44", "Grand Est"],
	["32", "Hauts-de-France"],
	["11", "Île-de-France"],
	["28", "Normandie"],
	["75", "Nouvelle-Aquitaine"],
	["76", "Occitanie"],
	["52", "Pays de la Loire"],
	["93", "Provence-Alpes-Côte d'Azur"],
];
