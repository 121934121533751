import React from "react";
import Modal from "react-bootstrap/Modal";
import {customAlphabet} from "nanoid/non-secure";

import "./Modal.css";

const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 8);

export class StackableModal extends React.Component {
	constructor(props) {
		super(props);

		this.id = "m_" + nanoid();
	}

	handleEnter = () => {
		const maxModalZIndex = Math.max(
			...[...document.querySelectorAll(".modal.show")].map((node) => parseFloat(window.getComputedStyle(node).zIndex)),
		);
		if (!isNaN(maxModalZIndex)) {
			const backdropNode = document.querySelector(".b-stackable-modal-backdrop." + this.id);
			if (backdropNode) backdropNode.style.zIndex = maxModalZIndex + 10;
			const modalNode = document.querySelector(".modal." + this.id);
			if (modalNode) {
				modalNode.style.zIndex = maxModalZIndex + 20;
				if (this.props.blurryBackdrop) {
					modalNode.style.backdropFilter = "blur(4px)";
				}
			}
		}

		if (typeof this.props.onEnter !== "undefined") this.props.onEnter();
	};

	render() {
		const modalProps = {
			...this.props,
			className: this.id,
			dialogClassName:
				(this.props.dialogClassName ? this.props.dialogClassName + " " : "") +
				"b-modal-dialog b-stackable-modal-dialog " +
				this.id,
			backdropClassName:
				(this.props.backdropClassName ? this.props.backdropClassName + " " : "") +
				"b-stackable-modal-backdrop " +
				this.id,
			onEnter: this.handleEnter,
		};

		delete modalProps.blurryBackdrop; // pour éviter warning react / prop non reconnue

		return <Modal {...modalProps} />;
	}
}

export function ModalCloseButton(props) {
	return (
		<button className="b-close-button" onClick={props.onClick}>
			&times;
		</button>
	);
}

export function ModalHeader(props) {
	return (
		<Modal.Header>
			<div>{props.children}</div>
			<ModalCloseButton onClick={props.onCloseButtonClick} />
		</Modal.Header>
	);
}

export function ModalBody(props) {
	return <Modal.Body>{props.children}</Modal.Body>;
}

export function ModalFooter(props) {
	return <Modal.Footer>{props.children}</Modal.Footer>;
}
