import React from "react";
import classNames from "classnames";

import "./InfoNote.css";

const InfoNote = (props) => (
	<div className={classNames("b-info-note", props.className)}>
		<i className="bi bi-info-circle-fill color-emphasis marginRight" />
		<div>{props.children}</div>
	</div>
);

export default InfoNote;
