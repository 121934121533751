import React from "react";
import "core-js/features/array/find";
import ReactSelect, {components} from "react-select";
import AsyncSelect from "react-select/async";

import "./Select.css";

const CustomControl = (props) => {
	// console.log(props);

	const componentProps = {...props};
	if (typeof props.selectProps.controlStyle !== "undefined") {
		componentProps.innerProps.style = props.selectProps.controlStyle;
	}
	if (typeof props.selectProps.controlClassName !== "undefined") {
		componentProps.className = props.selectProps.controlClassName;
	}

	return (
		// <div
		// ref={props.innerRef}
		// {...props.innerProps}
		// className="itemSelector2__control itemSelector2__custom-control"
		// style={typeof props.selectProps.controlStyle !== "undefined" ? props.selectProps.controlStyle : {}}
		// >
		// {props.children}
		// </div>
		<components.Control {...componentProps} />
	);
};

const CustomDropdownIndicator = (props) => (
	<components.DropdownIndicator {...props}>
		{props.selectProps.searchable ? <i className="bi bi-search" /> : <i className="bi bi-chevron-down" />}
	</components.DropdownIndicator>
);

const CustomSingleValue = (props) => {
	// console.log(props);
	const option = props.getValue();
	return (
		// <div className="itemSelector2__single-value">
		// {option && option[0] && typeof option[0].selectedValueLabel !== "undefined" ? option[0].selectedValueLabel : props.children}
		// </div>
		<components.SingleValue {...props}>
			{option && option[0] && typeof option[0].selectedValueLabel !== "undefined"
				? option[0].selectedValueLabel
				: props.children}
		</components.SingleValue>
	);
};

const CustomMenu = (props) => {
	const componentProps = {...props};
	if (typeof props.selectProps.menuClassName !== "undefined") {
		componentProps.className = props.selectProps.menuClassName;
	}

	return <components.Menu {...componentProps} />;
};

const CustomMenuList = (props) => {
	// const option = props.getValue();

	// console.log(props);
	return (
		<components.MenuList {...props}>
			{props.children}
			{/*option && option[0] && typeof option[0].selectedValueLabel !== "undefined" ? option[0].selectedValueLabel : props.children*/}
			{typeof props.selectProps.customMenuActions !== "undefined" && (
				<div className="menuActions">{props.selectProps.customMenuActions}</div>
			)}
		</components.MenuList>
	);
};

/*
const CustomInput = (props) => {
	
	return (
	<>
		{props.selectProps.searchable && <div className="searchIcon"><i className="bi bi-search"/></div>}
		<components.Input {...props}>
			{props.children}
		</components.Input>
	</>
	);
};
*/

export function Select(props) {
	var selectedOption = undefined;

	if (props.options) {
		if (typeof props.selectedValue !== "undefined") {
			if (props.groupedOptions) {
				const nbGroups = props.options.length;
				for (let iG = 0; iG < nbGroups; iG++) {
					let optionsGroup = props.options[iG];
					if (optionsGroup && optionsGroup.options) {
						selectedOption = optionsGroup.options.find(({value}) => value === props.selectedValue);
						if (selectedOption) break;
					}
				}
			} else {
				selectedOption = props.options.find(({value}) => value === props.selectedValue);
			}
		} else if (typeof props.selectedIndex !== "undefined") {
			selectedOption = props.options[props.selectedIndex];
		}
	}
	if (!selectedOption) selectedOption = null;

	const customComponents = {
		SingleValue: CustomSingleValue,
		IndicatorSeparator: null,
		DropdownIndicator: CustomDropdownIndicator,
		MenuList: CustomMenuList,
		// Input: CustomInput,
	};
	if (typeof props.controlStyle !== "undefined" || typeof props.controlClassName !== "undefined") {
		customComponents.Control = CustomControl;
	}
	if (typeof props.menuClassName !== "undefined") {
		customComponents.Menu = CustomMenu;
	}

	const SelectComponent = props.async ? AsyncSelect : ReactSelect;

	return (
		<SelectComponent
			{...props}
			className={
				"b-selector " +
				(typeof props.className !== "undefined" ? props.className : "") +
				(props.readOnly ? " readOnly" : "") +
				(props.disabled ? " disabled" : "") +
				(props.searchable ? " searchable" : "")
			}
			isSearchable={props.searchable === true}
			classNamePrefix="b-selector"
			// unstyled
			// required={props.required}
			defaultValue={typeof props.defaultValue !== "undefined" ? props.defaultValue : null}
			value={props.async ? undefined : selectedOption}
			onChange={props.onSelectOption}
			// options={props.options}
			isDisabled={props.readOnly || props.disabled}
			placeholder={typeof props.placeholder !== "undefined" ? props.placeholder : "Sélectionner une valeur"}
			noOptionsMessage={
				typeof props.noOptionsMessage !== "undefined" ? props.noOptionsMessage : () => "Aucun choix disponible"
			}
			components={customComponents}
			isOptionDisabled={(option) =>
				typeof option.isDisabled === "undefined"
					? false
					: typeof option.isDisabled === "function"
						? option.isDisabled()
						: !!option.isDisabled
			}
			// controlStyle={props.controlStyle}
			// menuIsOpen
		/>
	);
}
