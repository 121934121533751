import React from "react";
import classNames from "classnames";
import ElementWithTooltip from "./ElementWithTooltip";

import "./InfoTip.css";

const InfoTip = ({className, ...props}) => (
	<ElementWithTooltip {...props}>
		<i className={classNames("bi bi-info-circle-fill", "color-emphasis", "infoTip", className)} />
	</ElementWithTooltip>
);

export default InfoTip;
