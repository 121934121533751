import React, {useState, useCallback} from "react";
import {MainButton, SecondaryButton} from "./Button";
import {StackableModal, ModalBody, ModalHeader, ModalFooter} from "./Modal";
import {Select} from "./Select";
import {EditableTextArea} from "./EditableText";
import config from "../config";
import axios from "axios";

import "../App.css";
import "./ContactHelpDesk.css";

const contactReasonOptions = [
	{
		value: "other-company",
		label: "Je souhaite analyser une autre entreprise (préciser le SIREN recherché)",
	},
	{
		value: "connection-error",
		label: "Je rencontre un problème de connexion",
	},
	{
		value: "data-upload-error",
		label: "Je rencontre un problème de chargement de mes données financières",
	},
	{
		value: "need-help-to-analyze",
		label: "J'ai besoin d'aide pour analyser les résultats",
	},
	{
		value: "other-reason",
		label: "Autre (préciser)",
	},
];

function ContactHelpDesk(props) {
	const {
		// show,
		// onHide,
		userCode,
		logUserEvent,
	} = props;

	const [show, setShow] = useState(false);

	const [contactReason, setContactReason] = useState(null);
	const [contactMessage, setContactMessage] = useState("");
	const [isSending, setSending] = useState("");
	const [showAfterSend, setShowAfterSend] = useState(false);
	const [showSendError, setShowSendError] = useState(false);

	const handleShow = useCallback(() => {
		setShow(true);
	}, []);

	const handleHide = useCallback(() => {
		setShow(false);
	}, []);

	const handleEnter = useCallback(() => {
		setContactReason(null);
		setContactMessage("");
		setSending(false);
		setShowAfterSend(false);
		setShowSendError(false);
	}, []);

	const handleSelectContactOption = useCallback(({value}) => {
		setContactReason(value);
	}, []);

	const handleChangeContactMessage = useCallback((value) => {
		setContactMessage(value);
	}, []);

	const isSendAvailable = useCallback(() => {
		if (
			isSending ||
			contactReason === null ||
			((contactReason === "other-company" || contactReason === "other-reason") && contactMessage.length === 0)
		) {
			return false;
		} else return true;
	}, [isSending, contactReason, contactMessage]);

	const handleSend = useCallback(() => {
		setSending(true);
		setShowSendError(false);

		const reqConfig = {headers: {usercode: userCode}};

		const data = {
			userName: "",
			userEmail: "",
			userPhone: "",
			contactReason,
			contactMessage,
		};

		axios
			.post(config.api.server + config.api.root + "/support-email", data, reqConfig)
			.then((resp) => {
				setSending(false);

				if (resp && resp.data && resp.data.success) {
					setShowAfterSend(true);
					setShowSendError(false);
				} else {
					setShowSendError(true);
				}
			})
			.catch((error) => {
				console.log(error);
				setSending(false);
				setShowSendError(true);
			});

		logUserEvent({
			event: "contact_form",
			eventData: {
				reason: contactReason,
			},
		});
	}, [userCode, contactReason, contactMessage, logUserEvent]);

	return (
		<>
			{props.triggerComponent(handleShow)}

			<StackableModal
				dialogClassName="contact-help-desk-dialog"
				show={show}
				onHide={handleHide}
				centered
				keyboard
				onEnter={handleEnter}
			>
				<ModalHeader onCloseButtonClick={handleHide}>Contactez-nous !</ModalHeader>

				{showAfterSend ? (
					<>
						<ModalBody>
							<div className="centerAlign">
								Votre demande a bien été transmise à nos équipes,
								<br />
								qui reviendront vers vous prochainement.
							</div>
						</ModalBody>

						<ModalFooter>
							<MainButton onClick={handleHide}>Fermer</MainButton>
						</ModalFooter>
					</>
				) : (
					<>
						<ModalBody>
							<div className="marginBottom05">Indiquez la nature de votre demande</div>

							<Select
								className="contact-selector"
								selectedValue={contactReason}
								options={contactReasonOptions}
								onSelectOption={handleSelectContactOption}
								placeholder="Sélectionnez..."
							/>

							<div className="marginTop10 marginBottom05">Merci de préciser votre demande :</div>

							<EditableTextArea
								inputClassName=""
								placeholder=""
								defaultValue={contactMessage}
								maxCharacters={500}
								onChange={handleChangeContactMessage}
								displayNbCharHelper={true}
								changeEventDelay={400}
							/>
						</ModalBody>

						{showSendError && (
							<div className="centerAlign color-error marginBottom05">
								Une erreur s&apos;est produite, votre demande n&apos;a pu être envoyée.
								<br />
								Nous vous invitons à réessayer et, si le problème persiste, à contacter votre fédération.
							</div>
						)}

						<ModalFooter>
							<SecondaryButton onClick={handleHide}>Annuler</SecondaryButton>
							<MainButton isLoading={isSending} disabled={!isSendAvailable()} onClick={handleSend}>
								Envoyer
							</MainButton>
						</ModalFooter>
					</>
				)}
			</StackableModal>
		</>
	);
}

export default ContactHelpDesk;
