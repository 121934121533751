import React from "react";
import ReactDOM from "react-dom";
import {usePopperTooltip} from "react-popper-tooltip";

import "./SimpleTooltip.css";

const SimpleTooltip = (props) => {
	const {getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip(
		{
			placement: props.placement ? props.placement : "right",
			trigger: props.trigger ? props.trigger : "hover",
			delayShow: typeof props.delayShow !== "undefined" ? props.delayShow : 0,
			delayHide: typeof props.delayHide !== "undefined" ? props.delayHide : undefined,
			interactive: !!props.interactive,
		},
		{
			modifiers: [
				{
					name: "preventOverflow",
					enabled: props.preventOverflowDisabled !== true,
					options: {
						rootBoundary: "viewport",
					},
				},
				{
					name: "offset",
					enabled: true,
					options: {
						offset: [0, 10],
					},
				},
				{
					name: "computeStyles",
					enabled: true,
					options: {
						gpuAcceleration: false, // pour éviter aspect blurry des tooltips
					},
				},
			].concat(props.modifiers || []),
		},
	);

	return (
		<>
			{React.createElement(
				typeof props.triggerComponentTag !== "undefined" ? props.triggerComponentTag : "span",
				{
					ref: setTriggerRef,
					className: "trigger simpleTooltipTrigger",
				},
				props.children,
			)}
			{(visible || props.forceShow) &&
				!props.forceHide &&
				ReactDOM.createPortal(
					<div
						ref={setTooltipRef}
						{...getTooltipProps({
							className: "simpleTooltipContainer" + (props.className ? " " + props.className : ""),
							style: props.style ? props.style : {},
						})}
					>
						{props.tooltip}
						{!props.hideArrow && (
							<div
								{...getArrowProps({
									className: "simpleTooltipArrow",
									// "data-placement": placement,
								})}
							/>
						)}
					</div>,
					document.querySelector("#root"),
				)}
		</>
	);
};

export default SimpleTooltip;
