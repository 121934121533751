import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";
import config from "../config";
import axios from "axios";
import {Card, CardTitle} from "./Card";
import Table from "./Table";
import CustomRadioBtn from "./CustomRadioBtn";
import dayjs from "./dayjs-extended";
import {formatNumberLocaleString, base64ToBytesArray} from "./utils";
import {MainButton, SecondaryButton, LinkButton, IconButton} from "./Button";
import {StackableModal, ModalHeader, ModalBody, ModalFooter, ModalCloseButton} from "./Modal";
import classNames from "classnames";
import {NumericFormat, usePatternFormat, NumberFormatBase} from "react-number-format";
import {Select} from "./Select";
import ElementWithTooltip from "./ElementWithTooltip";
import InfoTip from "./InfoTip";
import {AccountsLoader, BilanCompletTemplateLink, BilanSimplifieTemplateLink} from "./AccountsLoader";
import Loader from "./Loader";
import {metrics, metricParams} from "./metric-parameters";
import fileSaver from "file-saver";
import {FinancialsOptions} from "./shared-components";
import {toast} from "react-toastify";

import "../App.css";
import "./Financials.css";

const financialsFormLayout = {
	C: [
		{
			section: "income-statement",
			sectionLabel: "COMPTE DE RÉSULTAT",
			subSections: [
				{
					id: "ops-revenues",
					label: "Produits d'exploitation",
					rows: [{code: "FC"}, {code: "FF"}, {code: "FI"}, {code: "FM"}, {code: "FN"}, {code: "FQ"}],
				},
				{
					id: "ops-expenses",
					label: "Charges d'exploitation",
					rows: [
						{code: "FS"},
						{code: "FT"},
						{code: "FU"},
						{code: "FV"},
						{code: "FW"},
						{code: "HP"},
						{code: "HQ"},
						{code: "FX"},
						{code: "FY"},
						{code: "FZ"},
						{code: "GE"},
					],
				},
				{
					id: "financial-income",
					label: "Résultat financier",
					rows: [{code: "GV"}],
				},
				{
					id: "extraordinary-income",
					label: "Résultat exceptionnel",
					rows: [{code: "HB"}, {code: "HD"}, {code: "HF"}, {code: "HH"}],
				},
				{
					id: "net-income",
					label: "Résultat net",
					rows: [{code: "HJ"}, {code: "HK"}, {code: "HN"}],
				},
			],
		},
		{
			section: "balance-sheet",
			sectionLabel: "BILAN",
			subSections: [
				{
					id: "assets",
					label: "Actif (montants nets)",
					rows: [{code: "BN"}, {code: "BP"}, {code: "BV"}, {code: "BX"}, {code: "CD"}, {code: "CF"}],
				},
				{
					id: "liabilities",
					label: "Passif",
					rows: [{code: "DL"}, {code: "DU"}, {code: "EH"}, {code: "DW"}, {code: "DX"}, {code: "EE"}],
				},
			],
		},
		{
			section: "off-balance",
			sectionLabel: "AUTRES",
			subSections: [
				{
					id: "misc",
					label: "",
					rows: [{code: "YP"}, {code: "YQ"}, {code: "YS"}, {code: "YT"}, {code: "YU"}, {code: "YY"}, {code: "YZ"}],
				},
			],
		},
	],
	S: [
		{
			section: "income-statement",
			sectionLabel: "COMPTE DE RÉSULTAT",
			subSections: [
				{
					id: "ops-revenues",
					label: "Produits d'exploitation",
					rows: [{code: "210"}, {code: "214"}, {code: "218"}, {code: "222"}, {code: "224"}, {code: "230"}],
				},
				{
					id: "ops-expenses",
					label: "Charges d'exploitation",
					rows: [
						{code: "234"},
						{code: "236"},
						{code: "238"},
						{code: "240"},
						{code: "242"},
						{code: "24B"},
						{code: "24A"},
						{code: "244"},
						{code: "250"},
						{code: "252"},
						{code: "262"},
					],
				},
				{
					id: "financial-income",
					label: "Résultat financier",
					rows: [{code: "280"}, {code: "294"}],
				},
				{
					id: "extraordinary-income",
					label: "Résultat exceptionnel",
					rows: [{code: "290"}, {code: "300"}],
				},
				{
					id: "net-income",
					label: "Résultat net",
					rows: [{code: "306"}, {code: "310"}],
				},
			],
		},
		{
			section: "balance-sheet",
			sectionLabel: "BILAN",
			subSections: [
				{
					id: "assets",
					label: "Actif (montants nets)",
					rows: [{code: "064"}, {code: "068"}, {code: "080"}, {code: "084"}],
				},
				{
					id: "liabilities",
					label: "Passif",
					rows: [{code: "142"}, {code: "156"}, {code: "164"}, {code: "166"}, {code: "180"}],
				},
			],
		},
		{
			section: "off-balance",
			sectionLabel: "AUTRES",
			subSections: [
				{
					id: "misc",
					label: "",
					rows: [{code: "376"}, {code: "374"}, {code: "378"}],
				},
			],
		},
	],
};

const financialsFormParams = {
	C: {
		FC: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Ventes de marchandises",
			min: 0,
		},
		FF: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Production vendue : biens",
		},
		FI: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Production vendue : services",
		},
		FM: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Production stockée",
		},
		FN: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Production immobilisée",
			min: 0,
		},
		FQ: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Autres produits d'exploitation",
			min: 0,
		},
		FS: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Achats de marchandises",
			min: 0,
		},
		FT: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Variation de stock de marchandises",
		},
		FU: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Achats de matières premières",
			min: 0,
		},
		FV: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Variation de stock de matières premières",
		},
		FW: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Charges externes",
			min: 0,
		},
		HP: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "dont crédit-bail mobilier",
			min: 0,
		},
		HQ: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "dont crédit-bail immobilier",
			min: 0,
		},
		FX: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Impôts et taxes",
			min: 0,
		},
		FY: {
			required: false,
			canBeProxied: false,
			unit: "€",
			label: "Salaires et traitements",
			min: 0,
		},
		FZ: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Charges sociales",
			min: 0,
		},
		GE: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Autres charges d'exploitation",
			min: 0,
		},
		GV: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Résultat financier",
		},
		HB: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Produits exceptionnels sur opés. en capital",
		},
		HD: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Total produits exceptionnels",
		},
		HF: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Charges exceptionnelles sur opés. en capital",
		},
		HH: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Total charges exceptionnelles",
		},
		HJ: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Participation des salariés",
			min: 0,
		},
		HK: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Impôt sur les bénéfices",
		},
		HN: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Résultat net",
		},
		BN: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "En cours de production de biens",
			min: 0,
		},
		BP: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "En cours de production de services",
			min: 0,
		},
		BV: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Avances et acomptes versés",
		},
		BX: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Créances clients",
			min: 0,
		},
		CD: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Valeurs mobilières de placement",
			min: 0,
		},
		CF: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Disponibilités",
			min: 0,
		},
		DL: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Total capitaux propres",
		},
		DU: {
			required: false,
			canBeProxied: false,
			unit: "€",
			label: "Emprunts et dettes auprès des éts de crédit",
			min: 0,
		},
		EH: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "dont comptes courants bancaires créditeurs",
			min: 0,
		},
		DW: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Avances et acomptes reçus",
			min: 0,
		},
		DX: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Dettes fournisseurs",
			min: 0,
		},
		EE: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Total passif",
			min: 0,
		},
		YP: {
			required: false,
			canBeProxied: true,
			unit: null,
			label: "Effectif moyen",
			min: 0,
		},
		YQ: {
			required: false,
			canBeProxied: false,
			unit: "€",
			label: "Engagements de crédit-bail mobilier",
			min: 0,
		},
		YS: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Effets escomptés non échus",
			min: 0,
		},
		YT: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Sous-traitance",
		},
		YU: {
			required: false,
			canBeProxied: false,
			unit: "€",
			label: "Personnel extérieur",
		},
		YY: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "TVA collectée",
		},
		YZ: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "TVA déductible sur biens et services",
		},
	},
	S: {
		210: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Ventes de marchandises",
			min: 0,
		},
		214: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Production vendue : biens",
		},
		218: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Production vendue : services",
		},
		222: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Production stockée",
		},
		224: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Production immobilisée",
			min: 0,
		},
		230: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Autres produits d'exploitation",
			min: 0,
		},
		234: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Achats de marchandises",
			min: 0,
		},
		236: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Variation de stock de marchandises",
		},
		238: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Achats de matières premières",
			min: 0,
		},
		240: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Variation de stock de matières premières",
		},
		242: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Charges externes",
			min: 0,
		},
		"24B": {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "dont crédit-bail mobilier",
			min: 0,
		},
		"24A": {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "dont crédit-bail immobilier",
			min: 0,
		},
		244: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Impôts et taxes",
			min: 0,
		},
		250: {
			required: false,
			canBeProxied: false,
			unit: "€",
			label: "Salaires et traitements",
			min: 0,
		},
		252: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Charges sociales",
			min: 0,
		},
		262: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Autres charges d'exploitation",
			min: 0,
		},
		280: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Produits financiers",
		},
		294: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Charges financières",
		},
		290: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Produits exceptionnels",
		},
		300: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Charges exceptionnelles",
		},
		306: {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Impôt sur les bénéfices",
		},
		310: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Résultat net",
		},
		"064": {
			required: false,
			canBeProxied: true,
			unit: "€",
			label: "Avances et acomptes versés",
		},
		"068": {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Créances clients",
			min: 0,
		},
		"080": {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Valeurs mobilières de placement",
			min: 0,
		},
		"084": {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Disponibilités",
			min: 0,
		},
		142: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Total capitaux propres",
		},
		156: {
			required: false,
			canBeProxied: false,
			unit: "€",
			label: "Emprunts et dettes assimilées",
			min: 0,
		},
		164: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Avances et acomptes reçus",
			min: 0,
		},
		166: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Dettes fournisseurs",
			min: 0,
		},
		180: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "Total passif",
			min: 0,
		},
		376: {
			required: false,
			canBeProxied: true,
			unit: null,
			label: "Effectif moyen",
			min: 0,
		},
		374: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "TVA collectée",
		},
		378: {
			required: true,
			canBeProxied: false,
			unit: "€",
			label: "TVA déductible sur biens et services",
		},
	},
};

const initFormValues = {
	C: {
		FC: null,
		FF: null,
		FI: null,
		FM: null,
		FN: null,
		FQ: null,
		FS: null,
		FT: null,
		FU: null,
		FV: null,
		FW: null,
		HP: null,
		HQ: null,
		FX: null,
		FY: null,
		FZ: null,
		GE: null,
		GV: null,
		HB: null,
		HD: null,
		HF: null,
		HH: null,
		HJ: null,
		HK: null,
		HN: null,
		BN: null,
		BP: null,
		BV: null,
		BX: null,
		CD: null,
		CF: null,
		DL: null,
		DU: null,
		EH: null,
		DW: null,
		DX: null,
		EE: null,
		YP: null,
		YQ: null,
		YS: null,
		YT: null,
		YU: null,
		YY: null,
		YZ: null,
	},
	S: {
		210: null,
		214: null,
		218: null,
		222: null,
		224: null,
		230: null,
		234: null,
		236: null,
		238: null,
		240: null,
		242: null,
		"24B": null,
		"24A": null,
		244: null,
		250: null,
		252: null,
		262: null,
		280: null,
		294: null,
		290: null,
		300: null,
		306: null,
		310: null,
		"064": null,
		"068": null,
		"080": null,
		"084": null,
		142: null,
		156: null,
		164: null,
		166: null,
		180: null,
		376: null,
		374: null,
		378: null,
	},
};

const unitSuffixes = {
	1: "",
	1000: "k",
	1000000: "m",
};

const periodLengthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map(
	(p) => ({value: p, label: p.toString()}),
);

function DateFormat(props) {
	const {format, ...rest} = usePatternFormat({
		...props,
		format: "## / ## / ####",
	});

	const _format = (val) => {
		let date = val.substring(0, 2);
		let month = val.substring(2, 4);
		const year = val.substring(4, 8);

		if (date.length === 1 && date[0] > 3) {
			date = "0" + date[0];
		} else if (date.length === 2) {
			// set the lower and upper boundary
			if (Number(date) === 0) {
				date = "01";
			}
			// else if (Number(date) > 31) {
			// date = "31";
			// }
		}

		if (month.length === 1 && month[0] > 1) {
			month = "0" + month[0];
		} else if (month.length === 2) {
			// set the lower and upper boundary
			if (Number(month) === 0) {
				month = "01";
			}
			// else if (Number(month) > 12) {
			// month = "12";
			// }
		}

		return format(date.toString() + month.toString() + year.toString());
	};

	return <NumberFormatBase format={_format} {...rest} />;
}

function Financials(props) {
	const {userCode, userCompanyData, setUserSession} = props;

	const navigate = useNavigate();

	if (!userCompanyData || !userCompanyData.siren) {
		navigate("/bienvenue");
	}

	const [isLoadingFinancials, setLoadingFinancials] = useState(true);
	const [isUpdatingFinancials, setUpdatingFinancials] = useState(false);
	const [isExportingFinancials, setExportingFinancials] = useState(false); // TODO

	const [financialsAction, setFinancialsAction] = useState({
		showAddFinancials: false,
		showFileUploader: false,
		showFinancialsEditor: false,
		showDeleteFinancialsWarning: false,
		financialsData: null,
	});
	const {showFinancialsEditor} = financialsAction;

	const years = useMemo(() => {
		const lastAvailableYear = dayjs().subtract(9, "month").year();
		return [0, 1, 2, 3, 4].map((i) => lastAvailableYear - i);
	}, []);

	const [financials, setFinancials] = useState(
		years.map((y) => ({
			year: y,
			data: null,
		})),
	);

	const getFinancials = useCallback(() => {
		setUpdatingFinancials(true);

		const reqConfig = {headers: {usercode: userCode}};
		axios
			.get(config.api.server + config.api.root + "/company/" + userCompanyData.siren + "/financials/list", reqConfig)
			.then((response) => {
				// console.log(response);

				setLoadingFinancials(false);
				setUpdatingFinancials(false);

				if (response.data.success && response.data.data) {
					setFinancials((prevFinancials) => {
						const newFinancials = [...prevFinancials];

						// response.data.data.forEach((bs) => {
						const nbYears = newFinancials.length;
						for (let iY = 0; iY < nbYears; iY++) {
							// const financialsIdx = newFinancials.findIndex(
							// ({year}) => year === dayjs(bs.fiscalClosingDate, "YYYY-MM-DD").year(),
							// );
							const financialsIdx = response.data.data.findIndex(
								({fiscalClosingDate}) => dayjs(fiscalClosingDate, "YYYY-MM-DD").year() === newFinancials[iY].year,
							);

							// if (financialsIdx > -1) {
							// newFinancials[financialsIdx] = {
							// ...newFinancials[financialsIdx],
							// data: bs,
							// };
							// }

							newFinancials[iY] = {
								...newFinancials[iY],
								data: financialsIdx > -1 ? response.data.data[financialsIdx] : null,
							};
						}

						return newFinancials;
					});
				}
			})
			.catch((error) => {
				console.log(error);
				setLoadingFinancials(false);
				setUpdatingFinancials(false);
			});
	}, [userCode, userCompanyData]);

	useEffect(() => {
		if (!userCompanyData || !userCompanyData.siren) {
			navigate("/bienvenue");
		} else {
			getFinancials();
		}
	}, [userCode, userCompanyData, userCompanyData.siren, navigate, getFinancials]);

	useEffect(() => {
		if (!showFinancialsEditor) {
			getFinancials();
		}
	}, [showFinancialsEditor, getFinancials]);

	useEffect(() => {
		setUserSession({financials: true});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleAddFinancials = useCallback((event) => {
		// console.log(event.currentTarget.value);
		setFinancialsAction({
			showAddFinancials: true,
			showFileUploader: false,
			showFinancialsEditor: false,
			showDeleteFinancialsWarning: false,
			financialsData: JSON.parse(event.currentTarget.value),
		});
	}, []);

	const handleEditFinancials = useCallback((event) => {
		// console.log(event.currentTarget.value);
		setFinancialsAction({
			showAddFinancials: false,
			showFileUploader: false,
			showFinancialsEditor: true,
			showDeleteFinancialsWarning: false,
			financialsData: JSON.parse(event.currentTarget.value),
		});
	}, []);

	const handleSaveFinancialsToXls = useCallback(
		(event) => {
			setExportingFinancials(true);

			const financialsData = JSON.parse(event.currentTarget.value);

			const reqConfig = {headers: {usercode: userCode}};
			axios
				.get(
					config.api.server +
						config.api.root +
						"/company/" +
						userCompanyData.siren +
						"/financials/xls/" +
						financialsData.data.idLiasse,
					reqConfig,
				)
				.then((resp) => {
					if (resp && resp.data.success && resp.data.data) {
						const result = resp.data.data;

						const blob = new Blob([base64ToBytesArray(result.base64)], {
							type: "application/vnd.ms-excel",
						});

						fileSaver.saveAs(blob, result.fileName);
					}

					setExportingFinancials(false);
				})
				.catch((error) => {
					console.log(error);
					setExportingFinancials(false);
				});
		},
		[userCode, userCompanyData],
	);

	const handleDeleteFinancials = useCallback((event) => {
		setFinancialsAction({
			showAddFinancials: false,
			showFileUploader: false,
			showFinancialsEditor: false,
			showDeleteFinancialsWarning: true,
			financialsData: JSON.parse(event.currentTarget.value),
		});
	}, []);

	const handleCancelDelete = () => {
		setFinancialsAction({
			showAddFinancials: false,
			showFileUploader: false,
			showFinancialsEditor: false,
			showDeleteFinancialsWarning: false,
			financialsData: null,
		});
	};

	const handleConfirmDelete = useCallback(() => {
		// console.log(financialsAction.financialsData);
		if (financialsAction && financialsAction.financialsData && financialsAction.financialsData.data) {
			const reqConfig = {headers: {usercode: userCode}};
			axios
				.delete(
					config.api.server +
						config.api.root +
						"/company/" +
						userCompanyData.siren +
						"/financials/" +
						financialsAction.financialsData.data.idLiasse,
					reqConfig,
				)
				.then(() => {
					getFinancials();
					setFinancialsAction({
						showAddFinancials: false,
						showFileUploader: false,
						showFinancialsEditor: false,
						showDeleteFinancialsWarning: false,
						financialsData: null,
					});
				})
				.catch((error) => {
					console.log(error);
					getFinancials();
					setFinancialsAction({
						showAddFinancials: false,
						showFileUploader: false,
						showFinancialsEditor: false,
						showDeleteFinancialsWarning: false,
						financialsData: null,
					});
				});
		}
	}, [financialsAction, userCode, userCompanyData, getFinancials]);

	// console.log(financials);
	// console.log(financialsAction.financialsData);
	return (
		<>
			<div className={classNames("financials", showFinancialsEditor ? "display-none" : "")}>
				<Card>
					<CardTitle>Chargez vos données financières</CardTitle>

					{isLoadingFinancials ? (
						<Loader />
					) : (
						<>
							<div>
								{financials &&
								financials.length > 0 &&
								financials.some((f) => f.data && f.data.source === "INPI" && f.data.status !== "unavailable") ? (
									<div>
										<p>Des états financiers sont disponibles au sein du RNE.</p>
										<p>Vous pouvez compléter les années manquantes par :</p>
									</div>
								) : (
									<div>
										<p>Aucun état financier n&apos;est disponible au sein du RNE.</p>
										<p>Vous pouvez ajouter vos données par :</p>
									</div>
								)}
								<FinancialsOptions />
							</div>

							<div className="marginTop30">
								{isUpdatingFinancials ? (
									<Loader />
								) : (
									<Table className="financials-table">
										<thead>
											<tr>
												<th>Année</th>
												<th>Source</th>
												<th>
													Fin de
													<br />
													situation
												</th>
												<th>
													Durée de
													<br />
													l&apos;exercice
												</th>
												<th>
													<div className="flex justifyCenter alignItemsCenter">
														<span>
															Statut des
															<br />
															comptes
														</span>
														<InfoTip
															className="marginLeft"
															tooltip={
																<>
																	<ul>
																		<li>
																			<span className="semiBold">Valides</span>
																			<br />
																			Les éléments sont complets et utilisables pour le calcul des indicateurs.
																		</li>
																		<li>
																			<span className="semiBold">Approximés</span>
																			<br />
																			Les éléments n&apos;ont pas été saisis en totalité, certaines valeurs sont
																			approximées par des moyennes sectorielles. Certains indicateurs peuvent être
																			calculés.
																		</li>
																		<li>
																			<span className="semiBold">Incomplets</span>
																			<br />
																			Les éléments n&apos;ont pas été saisis en totalité. Certaines valeurs requises
																			sont manquantes, les indicateurs ne peuvent pas être calculés.
																		</li>
																		<li>
																			<span className="semiBold">Indisponibles</span>
																			<br />
																			Les comptes ne sont pas disponibles au RNE : ils sont non déposés, confidentiels
																			ou inexploitables.
																		</li>
																	</ul>
																</>
															}
															placement="auto"
															trigger="hover"
															interactive
															delayShow={100}
															delayHide={100}
														/>
													</div>
												</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{financials.map(({year, data}) => (
												<tr key={year}>
													<td>{year}</td>
													<td>
														{data && (
															<>
																{data.source === "INPI" && <>RNE</>}
																{data.source === "USER" && (
																	<>
																		{data.sourceType === "form" && <>Saisie manuelle</>}
																		{data.sourceType === "file" && <>Import fichier</>}
																	</>
																)}
															</>
														)}
													</td>
													<td>
														{data && data.fiscalClosingDate
															? dayjs(data.fiscalClosingDate, "YYYY-MM-DD").format("DD/MM/YYYY")
															: ""}
													</td>
													<td>{data ? data.periodLength + " mois" : ""}</td>
													<td>
														{(!data || data.status === "unavailable") && (
															<span className="color-inactive">Indisponibles</span>
														)}
														{data && data.status === "valid" && <span className="color-success">Valides</span>}
														{data && data.status === "valid-missing-appendix" && (
														<>
															<span className="color-success">Valides</span>
															<ElementWithTooltip 
																tooltip={
																	<>
																		Des informations annexes (effectifs, TVA...) semblent manquantes dans les données publiées au RNE.<br/>
																		Pour les analyses, elles sont par défaut considérées comment valant 0.<br/>
																		Nous vous invitons à vérifier leur valeur.
																	</>
																}
															>
																<i className="bi bi-exclamation-triangle color-warning marginLeft"/>
															</ElementWithTooltip>
														</>
														)}
														{data && data.status === "proxy" && <span className="color-warning">Incomplets</span>}
														{data && data.status === "invalid" && <span className="color-error">Incomplets</span>}
													</td>
													<td>
														<div className="flex justifyCenter flexGap10">
															{!data || data.status === "unavailable" ? (
																<SecondaryButton onClick={handleAddFinancials} value={JSON.stringify({year, data})}>
																	<i className="bi bi-plus marginRight" />
																	Ajouter
																</SecondaryButton>
															) : (
																<>
																	<ElementWithTooltip tooltip="Editer les données" placement="top">
																		<IconButton onClick={handleEditFinancials} value={JSON.stringify({year, data})}>
																			<i className="bi bi-pencil-square" />
																		</IconButton>
																	</ElementWithTooltip>
																	<ElementWithTooltip tooltip="Exporter les données sous Excel" placement="top">
																		<IconButton
																			onClick={handleSaveFinancialsToXls}
																			value={JSON.stringify({year, data})}
																			disabled={isExportingFinancials}
																		>
																			<i className="bi bi-floppy" />
																		</IconButton>
																	</ElementWithTooltip>
																	<ElementWithTooltip tooltip="Ajouter de nouvelles données" placement="top">
																		<IconButton onClick={handleAddFinancials} value={JSON.stringify({year, data})}>
																			<i className="bi bi-plus-circle" />
																		</IconButton>
																	</ElementWithTooltip>
																	{data.source === "USER" && (
																		<ElementWithTooltip tooltip="Supprimer les données" placement="top">
																			<IconButton onClick={handleDeleteFinancials} value={JSON.stringify({year, data})}>
																				<i className="bi bi-trash" />
																			</IconButton>
																		</ElementWithTooltip>
																	)}
																</>
															)}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								)}
							</div>

							{!isUpdatingFinancials && (
								<MainButton className="marginTop20" onClick={() => navigate("/analyses")}>
									Accéder aux analyses
								</MainButton>
							)}
						</>
					)}
				</Card>
			</div>

			<AddFinancialsManager
				{...props}
				show={financialsAction.showAddFinancials}
				setFinancialsAction={setFinancialsAction}
				financialsData={financialsAction.financialsData}
			/>

			<AccountsLoader
				{...props}
				show={financialsAction.showFileUploader}
				setFinancialsAction={setFinancialsAction}
				getFinancials={getFinancials}
				financialsData={financialsAction.financialsData}
			/>

			<FinancialsEditor
				{...props}
				show={showFinancialsEditor}
				setFinancialsAction={setFinancialsAction}
				financialsYear={financialsAction.financialsData ? financialsAction.financialsData.year : null}
				accountsData={financialsAction.financialsData ? financialsAction.financialsData.data : null}
			/>

			{financialsAction.financialsData && (
				<StackableModal
					show={financialsAction.showDeleteFinancialsWarning}
					dialogClassName=""
					onHide={handleCancelDelete}
					keyboard
					centered
				>
					<ModalBody>
						<ModalCloseButton onClick={handleCancelDelete} />

						<div className="centerAlign marginTop20">
							Confirmez-vous la suppression des données pour l&apos;année&nbsp;
							{financialsAction.financialsData.year}&nbsp;?
						</div>
					</ModalBody>

					<ModalFooter>
						<MainButton onClick={handleCancelDelete}>Annuler</MainButton>
						<SecondaryButton onClick={handleConfirmDelete}>Confirmer</SecondaryButton>
					</ModalFooter>
				</StackableModal>
			)}
		</>
	);
}

function AddFinancialsManager(props) {
	const {setFinancialsAction, logUserEvent} = props;

	const handleCancel = useCallback(() => {
		setFinancialsAction({
			showAddFinancials: false,
			showFileUploader: false,
			showFinancialsEditor: false,
			financialsData: null,
		});
	}, [setFinancialsAction]);

	const handleUploadFile = useCallback(() => {
		setFinancialsAction((prevState) => ({
			...prevState,
			showAddFinancials: false,
			showFileUploader: true,
		}));
	}, [setFinancialsAction]);

	const handleEditFinancials = useCallback(() => {
		setFinancialsAction((prevState) => ({
			...prevState,
			showAddFinancials: false,
			showFinancialsEditor: true,
		}));

		logUserEvent({
			event: "financials_load",
			eventData: {
				load_mode: "user input",
			},
		});
	}, [setFinancialsAction, logUserEvent]);

	return (
		<StackableModal
			show={props.show}
			dialogClassName="add-financials-dialog"
			onHide={handleCancel}
			keyboard
			centered
			// onEnter={this.handleEnter}
			// onExited={this.handleExited}
		>
			<ModalHeader onCloseButtonClick={handleCancel}>Ajoutez vos données financières</ModalHeader>

			<ModalBody>
				<div className="centerAlign">Choisissez comment vous souhaitez ajouter vos données financières :</div>

				<div className="financials-input-options">
					<div className="marginTop15">
						<div className="flex justifyFlexStart">
							<i className="bi bi-filetype-xlsx fontSize14 marginRight10" />
							<div>
								<div>Liasse fiscale Excel</div>
								<div className="color-light-note fontSize09">Remplissez l&apos;un des modèles pour l&apos;importer</div>
								<div className="color-light-note fontSize09">
									{/*Modèle de formulaire de bilan complet (2050)*/}
									<BilanCompletTemplateLink className="block" />
									{/*Modèle de formulaire de bilan simplifié (2033)*/}
									<BilanSimplifieTemplateLink className="block" />
								</div>
							</div>
						</div>
						<div className="marginTop05 centerAlign">
							<MainButton onClick={handleUploadFile}>Importer une liasse fiscale Excel</MainButton>
						</div>
					</div>

					<div className="marginTop15">
						<div className="flex justifyFlexStart">
							<i className="bi bi-filetype-pdf fontSize14 marginRight10" />
							<div>
								<div>Liasse fiscale PDF</div>
								<div className="color-light-note fontSize09">
									Bilan complet : formulaire DGFIP 2050, 2051, 2052 et 2053
									<br />
									Bilan simplifié : formulaire DGFIP 2033 A et B
								</div>
							</div>
						</div>
						<div className="marginTop05 centerAlign">
							<MainButton onClick={handleUploadFile}>Importer une liasse fiscale PDF</MainButton>
						</div>
					</div>

					<div className="marginTop15">
						<div className="flex justifyFlexStart">
							<i className="bi bi-pencil-square fontSize14 marginRight10" />
							<div>
								<div>Saisie manuelle</div>
								<div className="color-light-note fontSize09">
									Informations du compte de résultat et du bilan nécessaires au calcul des indicateurs
								</div>
							</div>
						</div>
						<div className="marginTop05 centerAlign">
							<MainButton onClick={handleEditFinancials}>Saisir les données</MainButton>
						</div>
					</div>

					<div className="marginTop15">
						<div className="flex justifyFlexStart">
							<i className="bi bi-filetype-xlsx fontSize14 marginRight10" />
							<div>
								<div>Sauvegarde d&apos;une saisie manuelle</div>
								<div className="color-light-note fontSize09">
									Fichier Excel sauvegardé à partir d&apos;une précédente saisie manuelle
								</div>
							</div>
						</div>
						<div className="marginTop05 centerAlign">
							<MainButton onClick={handleUploadFile}>Importer une sauvegarde Excel</MainButton>
						</div>
					</div>
				</div>
			</ModalBody>

			<ModalFooter>
				<SecondaryButton onClick={handleCancel}>Annuler</SecondaryButton>
			</ModalFooter>
		</StackableModal>
	);
}

function FinancialsEditor(props) {
	const {show, userCode, userCompanyData, setFinancialsAction} = props;

	const [isModified, setIsModified] = useState(false);
	const [isSaving, setSaving] = useState(false);

	const [accountsData, setAccountsData] = useState(props.accountsData ? {...props.accountsData} : null);
	// console.log(accountsData);
	// const [accountsUuid, setAccountsUuid] = useState(accountsData && accountsData.idLiasse ? accountsData.idLiasse : null);

	const getDefaultFinancialsDate = useCallback(
		() =>
			props.financialsYear
				? accountsData && accountsData.fiscalClosingDate
					? accountsData.fiscalClosingDate
					: props.financialsYear + "-12-31"
				: null,
		[accountsData, props.financialsYear],
	);
	const [financialsDate, setFinancialsDate] = useState(getDefaultFinancialsDate(accountsData));
	const financialsYear = dayjs(financialsDate).year();
	const isFinancialsDateAllowed = (values) => {
		if (values.formattedValue) {
			const [date, month, year] = values.formattedValue.split("/").map((s) => s.trim());

			if (Number(date) > 31) return false;
			if (Number(month) > 12) return false;

			if (!isNaN(Number(date)) && !isNaN(Number(month))) {
				if (!dayjs((isNaN(Number(year)) ? "2020" : year) + "-" + month + "-" + date, "YYYY-MM-DD", true).isValid()) {
					return false;
				}
			}

			return true;
		} else return false;
	};
	const handleChangeFinancialsDate = useCallback((values, sourceInfo) => {
		if (sourceInfo && sourceInfo.source === "event" && values.formattedValue) {
			const [date, month, year] = values.formattedValue.split("/").map((s) => s.trim());
			const d = dayjs(year + "-" + month + "-" + date, "YYYY-MM-DD", true);
			if (d.isValid()) {
				setFinancialsDate(d.format("YYYY-MM-DD"));
				setIsModified(true);
			} else {
				setFinancialsDate(null);
			}
		}
	}, []);

	const getDefaultPeriodLength = useCallback((data) => (data && data.periodLength ? data.periodLength : 12), []);
	const [periodLength, setPeriodLength] = useState(getDefaultPeriodLength(accountsData));
	const handleChangePeriodLength = useCallback(({value}) => {
		setPeriodLength(value);
		setIsModified(true);
	}, []);

	const getDefaultBalanceSheetTypeCode = useCallback(
		(data) => (data && data.balanceSheetTypeCode ? data.balanceSheetTypeCode : "C"),
		[],
	);
	const [balanceSheetTypeCode, setBalanceSheetTypeCode] = useState(getDefaultBalanceSheetTypeCode(accountsData));
	const handleChangeBalanceSheetTypeCode = useCallback((event) => {
		setBalanceSheetTypeCode(event.target.value);
	}, []);

	const [inputUnit, setInputUnit] = useState("1");
	const handleChangeInputUnit = useCallback((event) => {
		setInputUnit(event.target.value);
		// setIsModified(true);
	}, []);

	const [formValues, setFormValues] = useState({...initFormValues});
	const [proxySalesRatios, setProxySalesRatios] = useState(null);

	const getSalesAmount = useCallback(
		(balanceSheetTypeCode) => {
			var result = 0;
			if (formValues) {
				if (balanceSheetTypeCode === "C" && formValues[balanceSheetTypeCode]) {
					["FC", "FF", "FI"].forEach((code) => {
						if (formValues[balanceSheetTypeCode][code] && !isNaN(Number(formValues[balanceSheetTypeCode][code].value)))
							result += Number(formValues[balanceSheetTypeCode][code].value);
					});
				} else if (balanceSheetTypeCode === "S" && formValues[balanceSheetTypeCode]) {
					["210", "214", "218"].forEach((code) => {
						if (formValues[balanceSheetTypeCode][code] && !isNaN(Number(formValues[balanceSheetTypeCode][code].value)))
							result += Number(formValues[balanceSheetTypeCode][code].value);
					});
				}
			}
			return result;
		},
		[formValues],
	);

	const salesAmount = getSalesAmount(balanceSheetTypeCode);

	const isFormValueAllowed = ({value, floatValue, balanceSheetTypeCode, code}) => {
		return (
			value === "" ||
			(value === "-" &&
				(!financialsFormParams[balanceSheetTypeCode] ||
					!financialsFormParams[balanceSheetTypeCode][code] ||
					typeof financialsFormParams[balanceSheetTypeCode][code].min === "undefined" ||
					financialsFormParams[balanceSheetTypeCode][code].min < 0)) ||
			(!isNaN(floatValue) &&
				Math.abs(floatValue) < 1000000000000 &&
				(!financialsFormParams[balanceSheetTypeCode] ||
					!financialsFormParams[balanceSheetTypeCode][code] ||
					typeof financialsFormParams[balanceSheetTypeCode][code].min === "undefined" ||
					floatValue >= financialsFormParams[balanceSheetTypeCode][code].min))
		);
	};

	const handleChangeFormValue = useCallback(
		(values, sourceInfo, balanceSheetTypeCode, code) => {
			if (sourceInfo && sourceInfo.source === "event" && sourceInfo.event) {
				var value = values.value !== "" && !isNaN(values.floatValue) ? parseFloat(values.floatValue) : null;

				if (
					value !== null &&
					financialsFormParams[balanceSheetTypeCode] &&
					financialsFormParams[balanceSheetTypeCode][code] &&
					financialsFormParams[balanceSheetTypeCode][code].unit === "€"
				) {
					value *= Number(inputUnit);
				}

				setFormValues((prevState) => {
					const newState = {
						...prevState,
						[balanceSheetTypeCode]: {
							...prevState[balanceSheetTypeCode],
						},
					};

					const code = sourceInfo.event.target.name;

					if (value !== null) {
						newState[balanceSheetTypeCode][code] = {
							value: value,
							type: "input",
						};
					} else {
						if (
							proxySalesRatios &&
							financialsFormParams[balanceSheetTypeCode][code] &&
							!financialsFormParams[balanceSheetTypeCode][code].required &&
							financialsFormParams[balanceSheetTypeCode][code].canBeProxied &&
							salesAmount !== 0
						) {
							const proxyData = proxySalesRatios.find(({liasse_code}) => liasse_code === code);

							if (proxyData) {
								newState[balanceSheetTypeCode][code] = {
									value: Math.round(salesAmount * proxyData.sales_ratio).toString(),
									type: "proxy",
								};
							} else {
								newState[balanceSheetTypeCode][code] = null;
							}
						} else {
							newState[balanceSheetTypeCode][code] = null;
						}
					}

					return newState;
				});

				setIsModified(true);
			}
		},
		[
			// balanceSheetTypeCode,
			inputUnit,
			proxySalesRatios,
			salesAmount,
		],
	);

	const initMetricValues = useMemo(() => {
		const r = {};
		metrics.forEach((m) => {
			r[m] = {
				value: null,
				status: "invalid",
			};
		});
		return r;
	}, []);
	const [metricValues, setMetricValues] = useState(initMetricValues);
	// const [metricsUpdateRequestTimeout, setMetricsUpdateRequestTimeout] = useState(null);
	const metricsUpdateRequestTimeout = useRef(null);

	const getFinancialsValues = useCallback(() => {
		const reqConfig = {headers: {usercode: userCode}};

		if (accountsData && accountsData.idLiasse && financialsFormParams[balanceSheetTypeCode]) {
			// console.log(accountsData.idLiasse);
			axios
				.get(
					config.api.server +
						config.api.root +
						"/company/" +
						userCompanyData.siren +
						"/financials/" +
						accountsData.idLiasse,
					reqConfig,
				)
				.then((response) => {
					// console.log(response);

					if (response.data.success && response.data.data) {
						setFormValues((prevState) => {
							const newState = {
								...prevState,
								[balanceSheetTypeCode]: {
									...prevState[balanceSheetTypeCode],
								},
							};

							/*
						// Si inpi les valeurs sont à 0 par défaut
						
						if (accountsData.source === "INPI") {
							
							Object.keys(financialsFormParams[balanceSheetTypeCode]).forEach(liasse_code => {
								
								const inpiData = response.data.data.find(idata => idata.liasse_code === liasse_code);
								
								newState[balanceSheetTypeCode][liasse_code] = {
									value: (inpiData ? inpiData.amount : 0),
									type: "input",
								};
							});
						}
						else {
							
							response.data.data.forEach(({liasse_code, amount}) => {
								newState[balanceSheetTypeCode][liasse_code] = {
									value: amount,
									type: "input",
								};
							});
						}
						*/

							Object.keys(financialsFormParams[balanceSheetTypeCode]).forEach((liasse_code) => {
								if (
									typeof response.data.data[liasse_code] !== "undefined" &&
									response.data.data[liasse_code] !== null
								) {
									newState[balanceSheetTypeCode][liasse_code] = {
										value: response.data.data[liasse_code],
										type: "input",
									};
								}
							});

							return newState;
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [userCode, userCompanyData, accountsData, balanceSheetTypeCode]);

	const getFinancialsProxySalesRatios = useCallback(() => {
		if (financialsYear && !isNaN(financialsYear)) {
			const reqConfig = {headers: {usercode: userCode}};
			const url =
				config.api.server +
				config.api.root +
				"/accounts-sales-ratios?" +
				"sector=" +
				userCompanyData.companyBTPSectorCode +
				"&" +
				"accounts_type_code=" +
				balanceSheetTypeCode +
				"&" +
				"year=" +
				financialsYear;

			axios
				.get(url, reqConfig)
				.then((response) => {
					// console.log(response);

					if (response.data.success && response.data.data) {
						setProxySalesRatios(response.data.data);
					} else {
						setProxySalesRatios(null);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [userCode, userCompanyData, balanceSheetTypeCode, financialsYear]);

	const processMetricsUpdate = useCallback(() => {
		// setMetricsUpdateRequestTimeout(null);
		metricsUpdateRequestTimeout.current = null;

		const reqConfig = {headers: {usercode: userCode}};

		var url =
			config.api.server +
			config.api.root +
			"/metrics?" +
			"sector=" +
			userCompanyData.companyBTPSectorCode +
			"&" +
			"accounts_type_code=" +
			balanceSheetTypeCode +
			"&" +
			"period_length=" +
			periodLength +
			"&" +
			"year=" +
			financialsYear +
			"&";

		Object.keys(formValues[balanceSheetTypeCode]).forEach((code) => {
			if (
				formValues[balanceSheetTypeCode][code] &&
				formValues[balanceSheetTypeCode][code].type === "input" &&
				formValues[balanceSheetTypeCode][code].value !== "" &&
				!isNaN(Number(formValues[balanceSheetTypeCode][code].value))
			) {
				url += code + "=" + formValues[balanceSheetTypeCode][code].value + "&";
			}
		});

		axios
			.get(url, reqConfig)
			.then((response) => {
				// console.log(response);

				if (response.data.success && response.data.data) {
					setMetricValues(response.data.data);
				} else {
					setMetricValues(initMetricValues);
				}
			})
			.catch((error) => {
				console.log(error);
				setMetricValues(initMetricValues);
			});
	}, [userCode, userCompanyData, initMetricValues, formValues, balanceSheetTypeCode, periodLength, financialsYear]);

	useEffect(() => {
		if (show) {
			// setAccountsUuid(accountsData && accountsData.idLiasse ? accountsData.idLiasse : null);
			setAccountsData(props.accountsData ? props.accountsData : null);
			setIsModified(false);
		} else {
			// setAccountsUuid(null);
			setAccountsData(null);
		}
	}, [show, props.accountsData]);

	useEffect(() => {
		// if (show && accountsUuid) {
		if (show && accountsData && accountsData.idLiasse) {
			getFinancialsValues();
			getFinancialsProxySalesRatios();
		}
	}, [show, accountsData, getFinancialsValues, getFinancialsProxySalesRatios]);

	useEffect(() => {
		setFinancialsDate(getDefaultFinancialsDate(accountsData));
		setPeriodLength(getDefaultPeriodLength(accountsData));
		setBalanceSheetTypeCode(getDefaultBalanceSheetTypeCode(accountsData));
		setFormValues({...initFormValues});
	}, [accountsData, getDefaultFinancialsDate, getDefaultPeriodLength, getDefaultBalanceSheetTypeCode]);

	useEffect(() => {
		if (proxySalesRatios && salesAmount !== 0) {
			// console.log(salesAmount);

			setFormValues((prevState) => {
				const newState = {
					...prevState,
					[balanceSheetTypeCode]: {
						...prevState[balanceSheetTypeCode],
					},
				};

				if (financialsFormParams[balanceSheetTypeCode]) {
					Object.entries(financialsFormParams[balanceSheetTypeCode]).forEach(([code, {required, canBeProxied}]) => {
						// console.log(code, newState[balanceSheetTypeCode][code]);

						if (
							!required &&
							canBeProxied &&
							(!newState[balanceSheetTypeCode][code] || newState[balanceSheetTypeCode][code].type !== "input")
						) {
							const proxyData = proxySalesRatios.find(({liasse_code}) => liasse_code === code);

							if (proxyData) {
								newState[balanceSheetTypeCode][code] = {
									value: Math.round(salesAmount * proxyData.sales_ratio).toString(),
									type: "proxy",
								};
							}
						}
					});
				}

				return newState;
			});
		}
	}, [balanceSheetTypeCode, proxySalesRatios, salesAmount]);

	useEffect(() => {
		if (show) {
			if (metricsUpdateRequestTimeout.current !== null) {
				clearTimeout(metricsUpdateRequestTimeout.current);
			}
			metricsUpdateRequestTimeout.current = setTimeout(processMetricsUpdate, 400);
		}
		// eslint-disable-next-line
	}, [show, processMetricsUpdate]);

	const [isMetricsPanelCollapsed, setMetricsPanelCollapsed] = useState(true);

	const [showBackWarning, setShowBackWarning] = useState(false);
	const handleBack = () => {
		if (isModified) {
			setShowBackWarning(true);
		} else {
			confirmBack();
		}
	};

	const cancelBack = useCallback(() => {
		setShowBackWarning(false);
	}, []);

	const confirmBack = useCallback(() => {
		setShowBackWarning(false);
		setFinancialsAction({
			showAddFinancials: false,
			showFileUploader: false,
			showFinancialsEditor: false,
			accountsData: null,
		});
		setIsModified(false);
	}, [setFinancialsAction]);

	const handleSave = useCallback(() => {
		setSaving(true);

		const reqConfig = {headers: {usercode: userCode}};

		const values = [];

		Object.keys(formValues[balanceSheetTypeCode]).forEach((code) => {
			if (formValues[balanceSheetTypeCode][code] && formValues[balanceSheetTypeCode][code].type === "input") {
				const value = formValues[balanceSheetTypeCode][code].value;
				if (typeof value !== "undefined" && !isNaN(Number(value))) {
					values.push({
						code,
						// value:
						// value *
						// (financialsFormParams[balanceSheetTypeCode] &&
						// financialsFormParams[balanceSheetTypeCode][code] &&
						// financialsFormParams[balanceSheetTypeCode][code].unit !== null
						// ? Number(inputUnit)
						// : 1),
						value,
					});
				}
			}
		});

		const data = {
			accountsData: {
				...(accountsData || {}),
				// idLiasse: accountsData.accountsUuid,
				fiscalClosingDate: financialsDate,
				periodLength,
				balanceSheetTypeCode,
			},
			values,
		};
		// console.log(accountsUuid);

		axios
			.post(config.api.server + config.api.root + "/company/" + userCompanyData.siren + "/financials", data, reqConfig)
			.then((resp) => {
				setSaving(false);

				if (resp && resp.data && resp.data.success && resp.data.data) {
					// console.log(resp.data.data);

					// setAccountsUuid(resp.data.data.accountsUuid);
					setAccountsData((prevState) => ({
						...prevState,
						...resp.data.data,
					}));
					setInputUnit("1");
					setIsModified(false);

					toast.info("Vos données ont bien été enregistrées");
				}
			})
			.catch((error) => {
				console.log(error);
				setSaving(false);
			});
	}, [
		userCode,
		formValues,
		balanceSheetTypeCode,
		accountsData,
		userCompanyData,
		financialsDate,
		periodLength,
		// inputUnit,
	]);

	const handleSaveToXls = useCallback(() => {
		const reqConfig = {headers: {usercode: userCode}};
		axios
			.get(
				config.api.server +
					config.api.root +
					"/company/" +
					userCompanyData.siren +
					"/financials/xls/" +
					accountsData.idLiasse,
				reqConfig,
			)
			.then((resp) => {
				if (resp && resp.data && resp.data.success && resp.data.data) {
					const result = resp.data.data;

					const blob = new Blob([base64ToBytesArray(result.base64)], {
						type: "application/vnd.ms-excel",
					});

					fileSaver.saveAs(blob, result.fileName);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, [userCode, accountsData, userCompanyData]);

	const actionButtons = show && (
		<div className="flex justifyFlexStart flexGap10">
			<MainButton disabled={!isModified} onClick={handleSave} isLoading={isSaving}>
				Enregistrer
			</MainButton>
			{isModified ? (
				<ElementWithTooltip tooltip="Veuillez enregistrer les données avant de les exporter sur Excel">
					<SecondaryButton disabled>Sauvegarder sous Excel</SecondaryButton>
				</ElementWithTooltip>
			) : (
				<SecondaryButton onClick={handleSaveToXls}>Sauvegarder sous Excel</SecondaryButton>
			)}
		</div>
	);

	var nbTotalFields = 0;
	var nbRequiredFields = 0;
	var nbNonEmptyFields = 0;
	var nbNonEmptyRequiredFields = 0;
	Object.keys(financialsFormParams[balanceSheetTypeCode]).forEach((code) => {
		nbTotalFields++;
		const isRequired = financialsFormParams[balanceSheetTypeCode][code].required;
		if (isRequired) {
			nbRequiredFields++;
		}
		if (
			formValues[balanceSheetTypeCode][code] &&
			formValues[balanceSheetTypeCode][code].type === "input" &&
			formValues[balanceSheetTypeCode][code].value !== null
		) {
			nbNonEmptyFields++;
			if (isRequired) {
				nbNonEmptyRequiredFields++;
			}
		}
	});

	// console.log(nbTotalFields);
	// console.log(nbRequiredFields);
	// console.log(nbNonEmptyFields);
	// console.log(nbNonEmptyRequiredFields);
	return (
		show && (
			<>
				<div className="financials-editor">
					<Card>
						<CardTitle>Saisissez vos données financières</CardTitle>

						<LinkButton onClick={handleBack}>
							<i className="bi bi-chevron-left marginRight" />
							Retour à la liste des états financiers
						</LinkButton>

						<div className="financials-editor-form marginTop30">
							<div className="financials-parameters">
								<table>
									<tbody>
										<tr>
											<td>Date de clôture de l&apos;exercice</td>
											<td>
												<DateFormat
													className="date-number-format"
													mask="_"
													allowEmptyFormatting
													value={financialsDate ? dayjs(financialsDate, "YYYY-MM-DD").format("DDMMYYYY") : ""}
													isAllowed={isFinancialsDateAllowed}
													onValueChange={handleChangeFinancialsDate}
												/>
											</td>
										</tr>
										<tr>
											<td>Nombre de mois de l&apos;exercice</td>
											<td>
												<Select
													className="period-length-selector"
													selectedValue={periodLength}
													options={periodLengthOptions}
													onSelectOption={handleChangePeriodLength}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div className="bold marginTop20">
								Saisissez ci-dessous les montants nécessaires au calcul de vos indicateurs.
							</div>
							<div className="color-light-note fontSize09 marginTop05">
								Certaines données sont préremplies sur la base de moyennes sectorielles. Vous pouvez les compléter afin
								d&apos;augmenter la fiabilité des indicateurs calculés.
							</div>

							<div className="save-buttons marginTop20">{actionButtons}</div>

							<div className="flex justifySpaceBetween flexGap10 flexWrap marginTop20">
								<div className="flex justifyFlexStart flexGap10">
									<span>Bilan</span>
									<CustomRadioBtn
										name="balanceSheetTypeCode"
										value="C"
										onChange={handleChangeBalanceSheetTypeCode}
										checked={balanceSheetTypeCode === "C"}
										labelText="complet"
										// disabled={accountsData && accountsData.source === "INPI" && !isModified}
									/>
									<CustomRadioBtn
										name="balanceSheetTypeCode"
										value="S"
										onChange={handleChangeBalanceSheetTypeCode}
										checked={balanceSheetTypeCode === "S"}
										labelText="simplifié"
										// disabled={accountsData && accountsData.source === "INPI" && !isModified}
									/>
								</div>
								<div className="flex justifyFlexEnd flexGap10">
									<span>Saisie en</span>
									{["1", "1000", "1000000"].map((unit) => (
										<CustomRadioBtn
											key={unit}
											name="inputUnit"
											value={unit}
											onChange={handleChangeInputUnit}
											checked={inputUnit === unit}
											labelText={unitSuffixes[unit] + "€"}
										/>
									))}
								</div>
								<div>
									{balanceSheetTypeCode === "C" && (
										<div className="color-light-note fontSize09">
											Le format de bilan dit « complet » correspond au formulaire DGFIP n°2050/51/52/53. Les lignes du
											bilan et du compte de résultat sont identifiées par des codes à 2 lettres, repris ci-dessous.
										</div>
									)}
									{balanceSheetTypeCode === "S" && (
										<div className="color-light-note fontSize09">
											Le format de bilan dit « simplifié » correspond au formulaire DGFIP n°2033-A/B. Les lignes du
											bilan et du compte de résultat sont identifiées par des codes à 3 chiffres, repris ci-dessous.
										</div>
									)}
								</div>
							</div>

							<table className="financials-form">
								{financialsFormLayout[balanceSheetTypeCode] &&
									financialsFormLayout[balanceSheetTypeCode].map(({section, sectionLabel, subSections}) => (
										<tbody key={section} className="financials-form">
											<tr className="bold color-emphasis financials-form-section-row">
												<td colSpan={4}>
													<div>{sectionLabel}</div>
													<div className="weight-normal color-light-note italic fontSize09 marginTop05">
														Les informations pré-saisies dans les champs correspondent à une approximation sectorielle,
														lorsqu&apos;elle est disponible.
													</div>
												</td>
											</tr>
											{subSections.map(({id, label, rows}) => (
												<React.Fragment key={id}>
													<tr className="bold financials-form-subsection-row">
														<td colSpan={4}>{label}</td>
													</tr>
													{rows.map(({code}) => {
														const required =
															financialsFormParams[balanceSheetTypeCode] &&
															financialsFormParams[balanceSheetTypeCode][code] &&
															financialsFormParams[balanceSheetTypeCode][code].required;
														const unit =
															financialsFormParams[balanceSheetTypeCode] &&
															financialsFormParams[balanceSheetTypeCode][code]
																? financialsFormParams[balanceSheetTypeCode][code].unit
																: null;
														const suffix = unit ? " " + unitSuffixes[inputUnit] + unit : "";
														return (
															<tr key={code} className="financials-form-row">
																<td className="financials-form-row-label">
																	{financialsFormParams[balanceSheetTypeCode] &&
																	financialsFormParams[balanceSheetTypeCode][code]
																		? financialsFormParams[balanceSheetTypeCode][code].label
																		: null}
																</td>
																<td className="financials-form-row-code">{code}</td>
																<td className="financials-form-row-value">
																	<NumericFormat
																		className={
																			formValues[balanceSheetTypeCode][code]
																				? formValues[balanceSheetTypeCode][code].type
																				: ""
																		}
																		name={code}
																		value={
																			formValues[balanceSheetTypeCode][code] &&
																			formValues[balanceSheetTypeCode][code].type === "input"
																				? formValues[balanceSheetTypeCode][code].value /
																					(unit === "€" && Number(inputUnit) !== 0 ? Number(inputUnit) : 1)
																				: ""
																		}
																		decimalScale={0}
																		suffix={suffix}
																		thousandSeparator=" "
																		onValueChange={(values, sourceInfo) =>
																			handleChangeFormValue(values, sourceInfo, balanceSheetTypeCode, code)
																		}
																		isAllowed={({value, floatValue}) =>
																			isFormValueAllowed({value, floatValue, balanceSheetTypeCode, code})
																		}
																		placeholder={
																			formValues[balanceSheetTypeCode][code] &&
																			formValues[balanceSheetTypeCode][code].type === "proxy"
																				? formatNumberLocaleString(formValues[balanceSheetTypeCode][code].value, 0) +
																					suffix
																				: ""
																		}
																	/>
																</td>
																<td className="financials-form-row-required">
																	{required && <div className="status-tag required">Requis</div>}
																</td>
															</tr>
														);
													})}
												</React.Fragment>
											))}
										</tbody>
									))}
							</table>

							<div className="marginTop20">{actionButtons}</div>

							<div className="color-light-note fontSize09 marginTop05">
								Les données saisies sont conservées uniquement le temps de la session, pendant une durée de 4h. Vous
								pouvez à tout moment les télécharger au format Excel pour les réutiliser lors d&apos;une autre session.
							</div>
						</div>
					</Card>
				</div>

				<div className={classNames("financials-metrics-panel", isMetricsPanelCollapsed ? "collapsed" : "")}>
					<div className="metrics-panel-collapse">
						<IconButton onClick={() => setMetricsPanelCollapsed((prevState) => !prevState)}>
							{isMetricsPanelCollapsed ? <i className="bi bi-chevron-left" /> : <i className="bi bi-chevron-right" />}
						</IconButton>
					</div>

					<div className="financials-metrics">
						<div className="bold">Prévisualisez les indicateurs calculés à partir des données saisies</div>
						<div className="color-light-note fontSize09 marginTop05">
							Lorsque les données nécessaires au calcul sont des valeurs approximées, ou ne sont pas fournies,
							l&apos;indicateur de fiabilité vous indique les données à compléter.
						</div>

						<div className="flex justifyFlexStart alignItemsCenter flexGap10 marginTop15">
							<div className="metrics-progress-bar">
								<div className="metrics-progress-bar-track" />
								<div
									className={classNames(
										"metrics-progress-bar-progress",
										nbNonEmptyFields === nbTotalFields
											? "valid"
											: nbNonEmptyRequiredFields < nbRequiredFields
												? "invalid"
												: "proxy",
									)}
									style={{
										width: (nbRequiredFields !== 0 ? (nbNonEmptyFields / nbTotalFields) * 100 : 0) + "%",
									}}
								/>
							</div>
							<div>
								{nbNonEmptyFields}/{nbTotalFields} données saisies
							</div>
						</div>

						<table className="metrics-table marginTop15">
							<thead>
								<tr>
									<th className="metrics-name">Indicateur</th>
									<th className="metrics-value">Valeur</th>
									<th className="metrics-reliability">Fiabilité</th>
								</tr>
							</thead>
							<tbody>
								{metrics.map((metric) => (
									<tr key={metric}>
										<td className="metrics-name">
											<ElementWithTooltip tooltip={metricParams[metric].description} placement="top">
												{metricParams[metric].label}
											</ElementWithTooltip>
										</td>
										<td className="metrics-value">
											{typeof metricValues[metric] !== "undefined" &&
												(metricValues[metric].status === "valid" || metricValues[metric].status === "proxy") &&
												(typeof metricValues[metric].value !== "undefined" &&
												metricValues[metric].value !== null &&
												!isNaN(metricValues[metric].value) ? (
													<span className={metricValues[metric].status === "proxy" ? "color-inactive" : ""}>
														{metricParams[metric].unit === "percent" &&
															formatNumberLocaleString(metricValues[metric].value * 100, 1, 1) + " %"}
														{metricParams[metric].unit === "none" &&
															formatNumberLocaleString(metricValues[metric].value, 1, 1)}
													</span>
												) : (
													<span className="color-inactive">-</span>
												))}
										</td>
										<td className="metrics-reliability">
											{typeof metricValues[metric] !== "undefined" &&
												typeof metricValues[metric].status !== "undefined" && (
													<>
														{metricValues[metric].status === "valid" && (
															<ElementWithTooltip tooltip="Toutes les données sont disponibles" placement="top">
																<i className="bi bi-check-circle-fill color-success" />
															</ElementWithTooltip>
														)}
														{metricValues[metric].status === "proxy" && (
															<>
																<span className="color-warning">Valeur approximée</span>
																<InfoTip
																	className="marginLeft"
																	tooltip={
																		<>
																			<p>
																				{metricValues[metric].missingOptionalValues &&
																				metricValues[metric].missingOptionalValues.length > 0 ? (
																					<>
																						Les valeurs suivantes sont approximées :
																						{metricValues[metric].missingOptionalValues.map((code) => (
																							<React.Fragment key={code}>
																								<br />
																								{code +
																									(financialsFormParams[balanceSheetTypeCode] &&
																									financialsFormParams[balanceSheetTypeCode][code]
																										? " - " + financialsFormParams[balanceSheetTypeCode][code].label
																										: "")}
																							</React.Fragment>
																						))}
																					</>
																				) : (
																					<>Certaines données sont approximées.</>
																				)}
																			</p>
																			Veuillez les compléter pour augmenter la fiabilité de l&apos;indicateur.
																		</>
																	}
																	placement="top"
																/>
															</>
														)}
														{metricValues[metric].status === "invalid" && (
															<>
																<span className="color-inactive">Données insuffisantes</span>
																<InfoTip
																	className="marginLeft"
																	tooltip={
																		<>
																			<p>
																				{metricValues[metric].missingMandatoryValues &&
																				metricValues[metric].missingMandatoryValues.length > 0 ? (
																					<>
																						Les valeurs suivantes sont manquantes :
																						{metricValues[metric].missingMandatoryValues.map((code) => (
																							<React.Fragment key={code}>
																								<br />
																								{code +
																									(financialsFormParams[balanceSheetTypeCode] &&
																									financialsFormParams[balanceSheetTypeCode][code]
																										? " - " + financialsFormParams[balanceSheetTypeCode][code].label
																										: "")}
																							</React.Fragment>
																						))}
																					</>
																				) : (
																					<>Certaines données requises sont manquantes.</>
																				)}
																			</p>
																			Veuillez les compléter pour permettre le calcul de l&apos;indicateur.
																		</>
																	}
																	placement="top"
																/>
															</>
														)}
													</>
												)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				<StackableModal show={showBackWarning} dialogClassName="" onHide={cancelBack} keyboard centered>
					<ModalBody>
						<p className="centerAlign">Des modifications n&apos;ont pas été sauvegardées.</p>
						<p className="centerAlign">
							Etes-vous sûr de vouloir revenir à la liste
							<br />
							des états financiers <b>sans sauvegarder</b> ?
						</p>
					</ModalBody>

					<ModalFooter>
						<MainButton onClick={cancelBack}>Annuler</MainButton>
						<SecondaryButton onClick={confirmBack}>Confirmer</SecondaryButton>
					</ModalFooter>
				</StackableModal>
			</>
		)
	);
}

export default Financials;
