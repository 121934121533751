export const cloneObject = (obj) => {
	if (obj === null) return null;
	const clone = Array.isArray(obj) ? [] : {};
	for (let i in obj) {
		if (obj[i] !== null && typeof obj[i] === "object") clone[i] = cloneObject(obj[i]);
		else clone[i] = obj[i];
	}
	return clone;
};

export const formatNumberLocaleString = (num, precision, fixedDecimalScale) => {
	if (typeof num === "undefined" || num === null || isNaN(num)) return "-";

	if (typeof precision !== "undefined") {
		num = Math.round(num * 10 ** precision) / 10 ** precision;
	}
	var parts = num.toString().split(".");
	if (fixedDecimalScale === true) {
		if (parts.length === 2) {
			while (parts[1].length < precision) {
				parts[1] += "0";
			}
		}
		else if (parts.length === 1 && precision > 0) {
			for (let i = 0; i < precision; i++) {
				parts.push("0");
			}
		}
	}
	return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (parts[1] ? "," + parts[1] : "");
};

export const base64ToBytesArray = (encoded) => {
	const decodedString = atob(encoded);
	const length = decodedString.length;
	const buffer = new ArrayBuffer(length);
	const array = new Uint8Array(buffer);
	for (let i = 0; i < length; i++) {
		array[i] = decodedString.charCodeAt(i);
	}
	return array;
};
