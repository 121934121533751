import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {LinkButton} from "./Button";

import "../App.css";
import "./NotFound.css";

function NotFound() {
	const nav = useNavigate();
	const navigate = useCallback(
		(event) => {
			nav(event.currentTarget.value);
		},
		[nav],
	);

	return (
		<div className="not-found">
			<img src={require("../images/misc/404.jpg")} alt="404" />
			<div>
				<h1>Désolé, cette page n&apos;est pas accessible !</h1>
				<LinkButton onClick={navigate} value="/bienvenue">
					Retour à l&apos;accueil
				</LinkButton>
			</div>
		</div>
	);
}

export default NotFound;
