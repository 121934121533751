import React from "react";
import classNames from "classnames";
import Loader from "./Loader";

import "./Button.css";

export const MainButton = (props) => (
	<button
		className={classNames("b-button", "main-button", props.isLoading ? "loading" : "", props.className)}
		onClick={props.onClick}
		value={props.value}
		disabled={props.disabled}
	>
		{props.isLoading ? <Loader /> : props.children}
	</button>
);

export const SecondaryButton = (props) => (
	<button {...props} className={classNames("b-button", "secondary-button", props.className)}>
		{props.children}
	</button>
);

export const InformationButton = (props) => (
	<button {...props} className={classNames("b-button", "information-button", props.className)}>
		{props.children}
	</button>
);

export const LinkButton = (props) => (
	<button {...props} className={classNames("b-button", "link-button", props.className)}>
		{props.children}
	</button>
);

export const IconButton = (props) => (
	<button {...props} className={classNames("b-button", "icon-button", props.className)}>
		{props.children}
	</button>
);
